import React from "react";
import "./Footer.css";
import FirstSection from "./FirstSection/FirstSection.jsx";
import SecondSection from "./SecondSection/SecondSection";
import { Row, Container, Col } from "react-bootstrap";

const Footer = () => {
  const DateCopy = new Date().getFullYear();

  return (
    <>
      <Container
        fluid
        className={`w-100 p-0 m-0 mt-5 d-flex justify-content-around align-items-end pb-3 wave`}
      >
        <Row
          className={`justify-content-md-around justify-content-sm-center p-0 mx-0 align-items-start wave-width`}
        >
          <Col xs={12} sm={8} md={4} className="py-0 px-4 mx-0 mb-0">
            <FirstSection />
          </Col>
          <Col xs={12} sm={8} md={4} className="px-4 mx-0 mt-2 mb-0">
            <SecondSection />
          </Col>
          <Col xs={12} className="text-center my-3 copy-right-text">
            SmartBoxDigtal &#169; {DateCopy}. All Rights Reserved.
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
