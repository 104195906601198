import React from "react";
import "./HomeWhatWeDoSection.css";
import RenderCardWhatWeDo from "./renderCardWhatWeDo/RenderCardWhatWeDo.jsx";
import { Container, Row, Col } from "react-bootstrap";

const HomeWhatWeDoSection = () => {
  return (
    <Container
      fluid
      style={{ backgroundColor: "#371b51", borderRadius: "20px" }}
      className="my-5 p-0 mx-0"
    >
      <Row className="w-100 align-items-center m-0 p-0">
        <Col xs={12} className="d-flex justify-content-center">
          <h2 id="title-what-we-do">What We Do &#63;</h2>
        </Col>
        <Col xs={12} className="d-flex justify-content-center">
          <p id="description-what-we-do">
            SmartBoxDigital is the next gen of creating new
            technologies/innovations involves exploiting a space in the
            environment through entrepreneurial or new-business development
            activities.
          </p>
        </Col>
        <Container fluid id="section-card-home">
          <RenderCardWhatWeDo />
        </Container>
      </Row>
    </Container>
  );
};

export default HomeWhatWeDoSection;
