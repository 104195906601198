import React from "react";
import "./HomeTestimonialSection.css";
import { Container, Row, Col } from "react-bootstrap";
import RenderTestimonial from "./renderTestimonial/RenderTestimonial.jsx";

const HomeTestimonialSection = () => {
  return (
    <Container fluid id="testimonial-section" className="my-3">
      <Row>
        <Col xs={12}></Col>
        <Col xs={12} className="h-100">
          <RenderTestimonial />
        </Col>
      </Row>
    </Container>
  );
};

export default HomeTestimonialSection;
