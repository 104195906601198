import React from "react";
import "./CareerEndSection.css";
import Row from "react-bootstrap/esm/Row";

const CareerEndSection = () => {
  return (
    <Row className="my-5 py-5 text-center justify-content-center">
      <p className="distinct-description mb-4">
        We providing employers executive and temporary staffing for computer
        programming professionals.
      </p>
      <p className="description-end-section px-3">
        Computer programming deals with the design, writing, testing and
        debugging of computer programs. Utilizing source code (si aici enumeram
        tehnologiile de mai sus) computer programmers have the abilities to
        develop software and utilize computers to perform specific tasks and
        behaviors through programming of machines. Also, we can provide staffing
        for other programming languages according to the clients' requests.
      </p>
    </Row>
  );
};

export default CareerEndSection;
