import React from "react";
import "./CourseForm.css";
import { Container, Row, Col } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { itemsAdvantages } from "../../utils/data/itemsAdvantages";
import FormAplication from "./FormAplication/FormAplication";
// import Collapse from 'react-bootstrap/Collapse';
// import SbdButton from '../../components/button/button/SbdButton';

const CoureseForm = () => {
  // const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <Row className="p-0 m-0">
        <Col xs={12} className="mt-5">
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            <h1 className="title-contact">APPLY NOW</h1>
            <h1 className="title-us">TO OUR COURSES!</h1>
          </div>
        </Col>
        <Row className="linear-gradient justify-content-center px-0 mx-0">
          {/* <SbdButton
                        handleClick={() => setOpenForm(!openForm)}
                        title={'Apply Now!'}
                        variant={'secondary'}
                        width={250}
                        aria-controls="example-collapse-text"
                        aria-expanded={openForm}
                    >
                        click
                    </SbdButton> */}
          {/* <Collapse in={openForm} className='w-100 px-0 mx-0'> */}
          <div id="example-collapse-text">
            <FormAplication />
          </div>
          {/* </Collapse> */}
        </Row>
        <Row className="justify-content-center my-0  mx-0 px-0">
          <Container fluid className="px-2 mx-0 d-flex justify-content-center">
            <Container className="mx-0 px-0">
              <Col xs={12} className=" my-4 pb-3">
                <p className=" subtitle-advantages px-2">
                  Some of the advantages of being a developer :
                </p>
              </Col>
              <ListGroup as="ul">
                {itemsAdvantages.map((item, index) => {
                  return (
                    <ListGroup.Item
                      key={`list-of-advantages-${index}`}
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto d-flex flex-column">
                        <div className=" advantage-list-title mb-1">
                          {item?.advantage}
                        </div>
                        {item?.description ? (
                          <p className="m-0 py-0 px-3">{item?.description}</p>
                        ) : (
                          <></>
                        )}
                        {item?.link ? (
                          <a
                            href={item?.link}
                            target={"_blanck"}
                            className={"color-black px-3"}
                          >
                            Link for more information
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Container>
          </Container>
        </Row>
      </Row>
    </>
  );
};

export default CoureseForm;
