import React from "react";
import "./Home.css";
import HomeHeroSection from "./componentsHome/homeHeroSection/HomeHeroSection.jsx";
import HomeWhatWeDoSection from "./componentsHome/homeWhatWeDoSection/HomeWhatWeDoSection.jsx";
import HomeLeaveEmailSection from "./componentsHome/homeLeaveEmailSection/HomeLeaveEmailSection.jsx";
import HomeTestimonialSection from "./componentsHome/homeTestimonialSection/HomeTestimonialSection.jsx";
import HomeBlogSection from "./componentsHome/homeBlogSection/HomeBlogSection.jsx";
import { Container, Row, Col } from "react-bootstrap";

const Home = () => {
  return (
    <>
      <Container fluid>
        <Row className="flex-column align-items-center m-0">
          <Col md={8} xs={12} className="p-0 m-0">
            <HomeHeroSection />
            <HomeWhatWeDoSection />
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <HomeLeaveEmailSection />
        </Row>
        <Row className="flex-column align-items-center">
          <Col md={8} xs={12} className="p-0 m-0">
            <HomeTestimonialSection />
            <div
              style={{ backgroundColor: "#371b51", borderRadius: "20px" }}
              className="home-blog"
            >
              <HomeBlogSection />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
