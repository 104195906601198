// types
export const POST_LIKE_REQUEST = "POST_LIKE_REQUEST";
export const POST_LIKE_SUCCESS = "POST_LIKE_SUCCESS";
export const POST_LIKE_FAILURE = "POST_LIKE_FAILURE";

// actions
export const postLikeRequest = () => {
  return {
    type: POST_LIKE_SUCCESS,
  };
};

export const postLikeSuccess = (response) => {
  return {
    type: POST_LIKE_SUCCESS,
    payload: response,
  };
};

export const postLikeFailure = (response) => {
  return {
    type: POST_LIKE_SUCCESS,
    payload: response,
  };
};
