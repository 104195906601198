import React from "react";
import "./NotFoundPage.css";
import CardContainer from "../../components/container/card-container/CardContainer";
import { Container, Col, Row, Image } from "react-bootstrap";
import LogoSBD from "../../assets/logo/LogoSBD.svg";
import { LinkContainer } from "react-router-bootstrap";

const NotFoundPage = () => {
  return (
    <div className="error-bg">
      <p className="first-p">Oops!</p>
      <Container fluid className="linear-gradient w-50 mt-5 container-error">
        <Row>
          <Col className=" d-flex justify-content-center p-5">
            <CardContainer
              minHeight={200}
              minWidth={200}
              maxHeight={1000}
              maxWidth={600}
            >
              <h1 className="text-center">
                We can't find the page you are looking for, meet one of our
                services.
              </h1>
              <div className="gradient-line" />
              <h2 className="pagenotfound text-center">404 page not found</h2>
              <LinkContainer to="/">
                <Image
                  fluid
                  src={LogoSBD}
                  alt="sbdlogo"
                  className="ErrorSBDLOGO"
                />
              </LinkContainer>
            </CardContainer>
          </Col>
        </Row>
      </Container>
      <p className="second-p">
        You weren't supposed to see this, return to home page.
      </p>
    </div>
  );
};

export default NotFoundPage;
