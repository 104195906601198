import React from "react";
import "./Services.css";
import Cards from "./components-service/Cards.jsx";
import SbdButton from "../../components/button/button/SbdButton";
import WorkPeople from "../../assets/ilustration/consultancy.svg";
import { Container, Col, Row, Image } from "react-bootstrap";
import SubscribeSection from "../../components/subscribe-section/SubscribeSection";
import { useNavigate } from "react-router-dom";
import ContainerWhatHappens from "../../components/container/containerWhatHappens/ContainerWhatHappens";

const Services = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <SubscribeSection title={"Subscribe"} className="my-5" />
      <Container fluid className="p-0 m-0">
        <Container>
          <Row className="service-header mx-0 px-0">
            <Col xs={12} lg={6} className="mx-0 px-3">
              <h1 id="title-services" className="py-5 my-3 w-100 text-center">
                SERVICES
              </h1>
              <p className="text-center description-hero">
                As Martin Leblanc said, “A user interface is like a joke - if
                you have to explain it, it’s not that good”. We are focusing on
                fast prototyping and fine-tuning, going from wireframing to
                high-fidelity design. Every time a solution is started from
                scratch we always make sure we envision and design the prototype
                before coding. Our mindset is to always be cost-effective for
                the customer, so we want to make sure to understand what we are
                going to build and we are all (developers, stakeholders and
                business owners) on the same page to avoid rework sessions to
                zero. Tools that we are using: UI/UX - Photoshop, Sketch, Adobe
                Illustrator, AdobeXD, Invision, Figma.
              </p>
              <Row className="justify-content-center mt-5">
                <SbdButton
                  title="Contact Us"
                  handleClick={() => navigate("/contact")}
                ></SbdButton>
              </Row>
            </Col>
            <Col xs={12} lg={6} className="d-flex my-5">
              <Image src={WorkPeople} fluid alt="ilustration-hero" />
            </Col>
          </Row>
          <Row id="service-cards-section">
            <Col
              xs={12}
              className="d-flex flex-wrap justify-content-lg-around justify-content-md-center align-items-lg-start align-items-xs-center w-100 mx-0 px-0 mt-5"
            >
              <div
                style={{ backgroundColor: "#371b51", borderRadius: "20px" }}
                className="w-100 d-flex flex-wrap justify-content-xl-around justify-content-sm-center align-items-lg-start align-items-xs-center mt-5 p-0"
              >
                <Cards />
              </div>
            </Col>
          </Row>
        </Container>
        <Row className="py-5 mx-0 px-0">
          <ContainerWhatHappens />
        </Row>
      </Container>
    </>
  );
};

export default Services;
