//types
export const GET_MEMBERS_REQUEST = "GET_MEMBERS_REQUEST";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_FAILURE = "GET_MEMBERS_FAILURE";
export const CLEAR_MEMBERS = "CLEAR_MEMBERS";

//actions
export const getMembersRequest = () => {
  return {
    type: GET_MEMBERS_REQUEST,
  };
};

export const getMembersSuccess = (payload) => {
  return {
    type: GET_MEMBERS_SUCCESS,
    payload: payload,
  };
};

export const getMembersFailure = (payload) => {
  return {
    type: GET_MEMBERS_FAILURE,
    payload: payload,
  };
};

export const clearMembers = () => {
  return {
    type: CLEAR_MEMBERS,
  };
};
