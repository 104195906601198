import React from "react";
import "./HomeHeroSection.css";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import astronaut from "../../../../assets/ilustration/astronaut-ilustration.svg";
import SbdButton from "../../../../components/button/button/SbdButton.jsx";

const HomeHeroSection = () => {
  const navigate = useNavigate();

  return (
    <Container fluid className="h-100 w-100 m-0 p-0">
      <Row className="h-100 w-100 m-0 p-0">
        <Col
          xs={12}
          md={4}
          lg={6}
          className="float-end m-0 p-0"
          id="astronaut-description"
        >
          <Row className=" my-5 justify-content-center">
            <div className="d-flex justify-content-between align-items-center title-continer">
              <h1 className="title-design">DESIGN. </h1>
              <h1 className="title-develop">DEVELOP. </h1>
              <h1 className="title-deploy">DEPLOY.</h1>
            </div>
          </Row>
          <Row>
            <p className="text-left description-hero-home">
              We'll take care of your digital problems, so you can have the time
              to run your company properly.
            </p>
          </Row>
          <Row className="my-4 justify-content-start">
            <SbdButton
              id="home-hero-button"
              title="Contact Us"
              handleClick={() => navigate("/contact")}
              variant={"primary"}
            />
          </Row>
        </Col>
        <Col xs={12} md={8} lg={6}>
          <img
            className="w-100 h-100 section-astronaut"
            src={astronaut}
            alt="astronaut"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default HomeHeroSection;
