export const itemsTestimonial = [
  {
    testimonial:
      "Smart Box Digital helped us with our online platform for 4 months now and still helps us. Great service and highly recommended.",
    author: "-Fekete Alexandru ",
  },
  {
    testimonial:
      "Smart Box Digital main focus is to help the customers to reduce costs, improve quality and come up with the best long-term scalable products. ",
    author: "-Gabriel Cristea",
  },
  {
    testimonial: `SmartBoxDigital gave us a professional site that's easy to update."`,
    author: "- Josh Harris",
  },
  {
    testimonial:
      "Smart BoxDigital finds out just what the client wants and brings ideas that are even better than the initial vision.",
    author: "-Stefan Pommer",
  },
  {
    testimonial: `They really wanted to make a product that not only they were very proud of but that I was also extremely happy with.`,
    author: `James Murphy`,
  },
];
