import {
  GET_MEMBERS_REQUEST,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAILURE,
  CLEAR_MEMBERS,
} from "../types/membersTypes.js";

const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
  },
  members: [],
};

const membersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          status: false,
          message: action?.payload?.message,
        },
        members: action?.payload?.members,
      };
    case GET_MEMBERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          message: action?.payload?.message,
        },
      };
    case CLEAR_MEMBERS:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default membersReducer;
