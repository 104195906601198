import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";

const CardContainer = (props) => {
  const {
    id,
    minWidth,
    minHeight,
    maxHeight,
    maxWidth,
    padding,
    backgroundColor,
    borderRadius,
    shadow,
    margin,
  } = props;

  const getDropShadow = (shadow) => {
    switch (shadow) {
      case 0:
        return "0 0 0 #371b58";
      case 1:
        return ".1rem .1rem .3rem rgba(55, 27, 88, 0.5)";
      case 2:
        return ".2rem .2rem .5rem rgba(55, 27, 88, 0.5)";
      case 3:
        return ".3rem .3rem .7rem rgba(55, 27, 88, 0.5)";
      case 4:
        return ".4rem .4rem .9rem rgba(55, 27, 88, 0.5)";
      default:
        return ".2rem .2rem .5rem rgba(55, 27, 88, 0.5)";
    }
  };

  return (
    <Card
      id={id}
      key={id}
      style={{
        minWidth: `${minWidth}px`,
        minHeight: `${minHeight}px`,
        maxWidth: `${maxWidth}px`,
        maxHeight: `${maxHeight}px`,
        padding: `${padding}px`,
        backgroundColor: backgroundColor,
        borderRadius: `${borderRadius}px`,
        filter: `drop-shadow(${getDropShadow(shadow)})`,
        margin: `${margin}px`,
      }}
    >
      {props.children}
    </Card>
  );
};

CardContainer.defaultProps = {
  id: "",
  minWidth: 100,
  minHeight: 100,
  maxWidth: 100,
  maxHeight: 100,
  padding: 30,
  backgroundColor: "#FFFFFF",
  borderRadius: 20,
  shadow: 2,
  margin: 0,
};

CardContainer.propTypes = {
  id: PropTypes.string.isRequired,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  padding: PropTypes.number,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.number,
  shadow: PropTypes.number,
  margin: PropTypes.number,
};

export default CardContainer;
