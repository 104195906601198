import React from "react";
import "./ContainerContent.css";

const ContainerContent = (props) => {
  const { id, title, description } = props;
  return (
    <div
      className="content row d-flex align-items-center justify-content-center text-center p-5 mx-0"
      id={id}
      key={id}
    >
      <div className="col-xs-12 col-sm-10 col-md-8 col-lg-8">
        <h2 className="mb-4 text-center">{title}</h2>
        <p className="mb-4"> {description}</p>
      </div>
    </div>
  );
};

export default ContainerContent;
