import axios from "axios";
import { url } from "../../constants/constants";

const request = axios.create({
  baseURL: url,
});

request.interceptors.request.use(function (config) {
  config.headers.contentType = "*";
  config.headers.allowAccessControlOrigin = "";

  return config;
});

export { request };
