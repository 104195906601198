import React from "react";
import "./Contact.css";
import "../../components/box/contactUsForm/ContactUsForm";
import ContactUsForm from "../../components/box/contactUsForm/ContactUsForm";
import { Row } from "react-bootstrap";

const Contact = () => {
  return (
    <Row className="p-0 m-0">
      <Row className="p-0 m-0 " id="container-contact-hero">
        <div className="d-flex flex-row justify-content-center align-items-center">
          <h1 className="title-contact">CONTACT</h1>
          <h1 className="title-us">US</h1>
        </div>
      </Row>
      <Row className="justify-content-center  m-0" id="continer-form-section">
        <ContactUsForm />
      </Row>
    </Row>
  );
};

export default Contact;
