import React from "react";
import "./CareerHeroSection.css";
import { Container, Row, Col } from "react-bootstrap";
import CareerHeroIlustration from "../../../../assets/ilustration/consultancy.svg";

const CareerHeroSection = () => {
  return (
    <Container className="mt-5 pt-5">
      <Row className="d-flex justify-content-center">
        <div
          id="title-career"
          className="d-flex flex-column align-items-center title-continer pb-5"
        >
          <h1 className="title-remote">REMOTE</h1>
          <h1 className="title-staff">STAFF</h1>
        </div>
        <p className="text-center description-hero">
          We now live in a remote-first world. Today, almost every tech company
          is a remote company, thanks to the enormous benefits that fully remote
          teams offer.
        </p>
      </Row>
      <Row className="h-100  m-0 px-0 py-5">
        <Col xs={12} md={4} lg={6} className="m-0 p-0">
          <p
            className="w-100 h-100 text-center description-hero mx-0 px-0"
            id="career-hero-description"
          >
            Today’s workforce has changed, and as a result, more companies than
            ever are turning toward remote staffing. Though it may seem daunting
            to interview and hire someone who isn’t local, hiring remote workers
            couldn’t be easier! With our experienced recruiters and limitless
            database of nationwide professionals, we will deliver remote workers
            that align perfectly with your company’s culture and workforce
            objectives.
          </p>
        </Col>
        <Col xs={12} md={8} lg={6} className="py-4">
          <img
            className="w-100 h-100 section-career-hero-ilustration "
            src={CareerHeroIlustration}
            alt="career hero ilustration"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CareerHeroSection;
