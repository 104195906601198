import React from "react";
import "./AboutHeroSection.css";
import { Container, Row, Col } from "react-bootstrap";
import aboutHero from "../../../../assets/ilustration/rocket-ilustration.svg";

const AboutHeroSection = () => {
  return (
    <Container fluid className="h-100 w-100 my-5 mx-0 px-0">
      <Row className="h-100 w-100 m-0 p-0">
        <Col
          md={12}
          lg={6}
          className="float-end px-2 m-0"
          id="ilustration-description"
        >
          <Row className="my-5 justify-content-center">
            <div
              id="title-about-hero"
              className="d-flex flex-row justify-content-center"
            >
              <h1 className="title-who">WHO</h1>
              <h1 className="title-we">WE</h1>
              <h1 className="title-are">ARE?</h1>
            </div>
          </Row>
          <Row className="w-100 px-0 mx-0 ">
            <p className="text-center description-hero">
              We are SmartBoxDigital. FullStack Web Development and Design
              Agency. Our team it's filled with only dedicated and passionate
              people. We have the urge to finish and conceptualize costumer's
              problems. We discover new concepts and create amazing projects
              with you.
            </p>
          </Row>
        </Col>
        <Col md={12} lg={6}>
          <img
            className="w-100 h-100 section-ilustration my-3 m-0"
            src={aboutHero}
            alt="Hero ilustration"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AboutHeroSection;
