import React from "react";

const renderIcons = (arr) => {
  return arr.map((item, index) => {
    return (
      <a
        key={index}
        href={item.src}
        target="_blanck"
        rel="noreferrer"
        className="w-100 d-flex justify-content-center"
        aria-label={`${item.aria}`}
      >
        <i className={`${item.iconClass} icon-footer-section m-0 p-0`}></i>
      </a>
    );
  });
};

export default renderIcons;
