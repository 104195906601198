import React from "react";
import "./RenderMenu.css";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { menuItems } from "../../../utils/data/menuItems.js";
import PropTypes from "prop-types";
import SbdButton from "../../button/button/SbdButton";
import { useLocation } from "react-router-dom";

const RenderMenu = (props) => {
  const { show, setShow } = props;
  const location = useLocation();

  return (
    <>
      {menuItems.map((item, index) => {
        return (
          <Nav.Link key={`header-menu-button-${index}`}>
            <LinkContainer
              className="menu-tabs-container"
              to={item.src}
              onClick={() => (show ? setShow(false) : null)}
            >
              {item.value === "SERVICES" ? (
                <div className="mx-5 my-3">
                  <SbdButton
                    id={"header-button"}
                    title={item.value}
                    variant={"secondary"}
                  >
                    {item.value}
                  </SbdButton>
                </div>
              ) : (
                <button
                  className={`${
                    location.pathname === item.src ? "active-tabs" : ""
                  } menu-tabs`}
                >
                  {item.value}
                </button>
              )}
            </LinkContainer>
          </Nav.Link>
        );
      })}
    </>
  );
};

RenderMenu.defaultProps = {
  show: false,
  setShow: () => undefined,
};

RenderMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
export default RenderMenu;
