import { React, useState } from "react";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../../assets/logo/LogoSBD.svg";
import { LinkContainer } from "react-router-bootstrap";
import RenderMenu from "./menuItem/RenderMenu.jsx";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      {["xl"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          sticky="top"
          className="mt-0 py-0 shadow"
        >
          <Container fluid className="container-navigation ">
            <Navbar.Brand className="logo">
              <LinkContainer to="/">
                <img src={Logo} alt="Logo sbd" width={200} height={40} />
              </LinkContainer>
            </Navbar.Brand>
            <Navbar.Toggle
              onClick={() => setShowMenu(!showMenu)}
              aria-controls={`offcanvasNavbar-expand-${expand}`}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={showMenu}
            >
              <Offcanvas.Header>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                  className="w-100 d-flex justify-content-between"
                >
                  <LinkContainer to="/" onClick={() => setShowMenu(!showMenu)}>
                    <img
                      className="logo-drop"
                      src={Logo}
                      alt="Logo sbd"
                      width={200}
                      height={40}
                    />
                  </LinkContainer>
                  <span
                    onClick={() => setShowMenu(false)}
                    className="menu-close-button"
                  >
                    <i className="bi bi-x-lg"></i>
                  </span>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="w-100 align-items-center justify-content-end flex-gap-1">
                  <RenderMenu show={showMenu} setShow={setShowMenu} />
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default Header;
