// types
export const POST_SUBSCRIBE_REQUEST = "POST_SUBSCRIBE_REQUEST";
export const POST_SUBSCRIBE_SUCCESS = "POST_SUBSCRIBE_SUCCESS";
export const POST_SUBSCRIBE_FAILURE = "POST_SUBSCRIBE_FAILURE";

// actions
export const postSubscribeRequest = () => {
  return {
    type: POST_SUBSCRIBE_REQUEST,
  };
};

export const postSubscribeSuccess = (response) => {
  return {
    type: POST_SUBSCRIBE_SUCCESS,
    payload: response,
  };
};

export const postSubscribeFailure = (error) => {
  return {
    type: POST_SUBSCRIBE_FAILURE,
    payload: error,
  };
};
