import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SbdButton from "../../../components/button/button/SbdButton.jsx";
import CardContainer from "../../../components/container/card-container/CardContainer.jsx";
import Input from "../../../components/input/Input.jsx";
import { namePattern, emailPattern } from "../../../utils/patterns/patterns";
import Form from "react-bootstrap/Form";
import "./FormAplication.css";
import { postApply } from "../../../utils/redux/actions/applyActions.js";
import checkEmpty from "../../../utils/function/emptyInputs";

const FormAplication = () => {
  const initialForm = {
    name: "",
    email: "",
    graduatedSchool: "",
    englishLevel: "",
    afterEighteen: false,
    phoneNumber: "",
    developerSalary: "",
    budget: "",
  };
  const [inputs, setInputs] = useState(initialForm);
  const [disableButton, setDisableButton] = useState(false);
  const [notification, setNotification] = useState(false);

  const dispatch = useDispatch();

  const message = useSelector((state) => state?.applyState?.error);
  const loading = useSelector((state) => state?.applyState?.loading);

  const [data, setData] = useState(inputs);

  function sendApplyData() {
    dispatch(postApply(data, () => setInputs(initialForm)));
    setNotification(true);
  }

  useEffect(() => {
    checkEmpty(inputs, setData);
  }, [inputs]);

  return (
    <>
      <Row className="pb-5 px-2 m-0 justify-content-center flex-column align-items-center w-100">
        <Col xs={12} className="mb-3">
          <p className="text-white text-center w-100 text-before-apply-form">
            Please complete the form to register to our courses !
          </p>
        </Col>
        <CardContainer
          id="card-continer-apply"
          minWidth={250}
          minHeight={500}
          maxWidth={900}
          maxHeight={2000}
        >
          <Row className="flex-column my-4">
            <form className=" p-0 m-0 d-flex gap-3 align-items-center justify-content-center flex-column form-apply-courses">
              <Col xs={12} md={8}>
                <label htmlFor={"name"} className={"pb-2"}>
                  Enter full name!*
                </label>
                <Input
                  name={"name"}
                  type={"text"}
                  placeholder={"Name"}
                  errorMessage={
                    "Enter a valid name (Only letters, min 3 characters)"
                  }
                  state={inputs}
                  setState={setInputs}
                  width={"100%"}
                />
              </Col>
              <Col xs={12} md={8}>
                <label htmlFor={"email"} className={"pb-2"}>
                  Enter an email!*
                </label>
                <Input
                  name={"email"}
                  type={"email"}
                  placeholder={"Email"}
                  state={inputs}
                  setState={setInputs}
                  width={"100%"}
                />
              </Col>
              <Col xs={12} md={8}>
                <label htmlFor={"graduatedSchool"} className={"pb-2"}>
                  Education level, last graduated school.*
                </label>
                <Input
                  name={"graduatedSchool"}
                  type={"text"}
                  placeholder={"Graduated School"}
                  validator={`([^<>$]){4,}$`}
                  errorMessage={`This field need to have at least 4 characters`}
                  state={inputs}
                  setState={setInputs}
                  width={"100%"}
                />
              </Col>
              <Col xs={12} md={8}>
                <label htmlFor={"englishLevel"} className={"pb-2"}>
                  English level.*
                </label>
                <Input
                  name={"englishLevel"}
                  type={"text"}
                  placeholder={"English level"}
                  validator={`([^<>$]){2,}$`}
                  errorMessage={`This field need to be completed`}
                  state={inputs}
                  setState={setInputs}
                  width={"100%"}
                />
              </Col>
              <Col xs={12} md={8}>
                <div className="d-flex flex-column align-items-center">
                  <label htmlFor={"afterEighteen"} className={"pb-2"}>
                    Do you agree to participate at courses after 6 PM ?*
                  </label>
                  <Form.Check
                    inline
                    label="Yes"
                    name="afterEighteen"
                    value={inputs.afterEighteen}
                    type={"switch"}
                    onChange={() =>
                      setInputs({
                        ...inputs,
                        afterEighteen: !inputs.afterEighteen,
                      })
                    }
                    id={`switch-yes-input`}
                  />
                </div>
              </Col>
              <Col xs={12} md={8}>
                <label htmlFor={"phoneNumber"} className={"pb-2"}>
                  Enter your phone number!
                </label>
                <Input
                  name={"phoneNumber"}
                  type={"text"}
                  placeholder={"Phone Number"}
                  validator={`^[0-9]{10,13}$`}
                  errorMessage={`Please enter a valid phone number! (Only numbers, 10-13 character) `}
                  state={inputs}
                  setState={setInputs}
                  width={"100%"}
                  setDisableButton={setDisableButton}
                ></Input>
              </Col>
              <Col xs={12} md={8}>
                <label htmlFor={"developerSalary"} className={"pb-2"}>
                  What salary do you think a developer has?
                </label>
                <Input
                  name={"developerSalary"}
                  placeholder={"Estimated salary"}
                  state={inputs}
                  setState={setInputs}
                  width={"100%"}
                ></Input>
              </Col>
              <Col xs={12} md={8}>
                <label htmlFor={"budget"} className={"pb-2"}>
                  How much are you willing to pay for our course?
                </label>
                <Input
                  name={"budget"}
                  type={"text"}
                  placeholder={"Payment disponibility"}
                  state={inputs}
                  setState={setInputs}
                  width={"100%"}
                ></Input>
              </Col>
              <Col xs={12} md={8}>
                <p className=" text-end ">
                  <small>Field with * are required!</small>
                </p>
              </Col>
              <SbdButton
                width={250}
                title={
                  !loading ? "Send" : <Spinner animation="grow" size="sm" />
                }
                disabled={
                  !inputs.name ||
                  !inputs.email ||
                  !inputs.graduatedSchool ||
                  !inputs.englishLevel ||
                  !namePattern.test(inputs.name) ||
                  !emailPattern.test(inputs.email) ||
                  disableButton
                }
                variant={"primary"}
                handleClick={() => sendApplyData()}
              />
              <div
                className={`notification w-auto p-1 text-center justify-content-center ${
                  notification ? "d-flex" : "d-none"
                } ${message?.status ? "red-error" : "gray-error"}`}
              >
                {message?.message}
              </div>
            </form>
          </Row>
        </CardContainer>
      </Row>
    </>
  );
};

export default FormAplication;
