import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Overlay } from "react-bootstrap";
import {
  namePattern,
  emailPattern,
  passwordPattern,
} from "../../utils/patterns/patterns";

const Input = (props) => {
  const {
    name,
    type,
    placeholder,
    width,
    state,
    setState,
    rows,
    validator,
    errorMessage,
    setDisableButton,
  } = props;

  const [error, setError] = useState("");
  const target = useRef(null);

  const handleInput = (newValue) => {
    //A custom validator can be used for any type of input, this one will be used for validation
    //you can also add a custom error message or there will be used 'Incorrect data entered'
    if (validator) {
      const customPattern = new RegExp(validator);
      newValue && !customPattern.test(newValue)
        ? setError(errorMessage || "Incorrect data entered.")
        : setError("");
    } else
      switch (type) {
        case "text":
          if (name.toLowerCase().includes("name")) {
            newValue && !namePattern.test(newValue)
              ? setError(
                  "Enter a valid name. (Only letters, minimum 3 characters.)"
                )
              : setError("");
          }
          break;
        case "email":
          newValue && !emailPattern.test(newValue)
            ? setError("Enter a valid mail format. (name@example.com)")
            : setError("");
          break;
        case "password":
          newValue && !passwordPattern.test(newValue)
            ? setError("Enter a password format. (Password123!)")
            : setError("");
          break;
        default:
          console.log("type don't exist");
      }
    setState((prev) => ({
      ...prev,
      [name]: type !== "text" ? newValue?.trim() : newValue,
    }));
  };

  const inputProps = {
    ref: target,
    id: `${name}-input`,
    type: type,
    placeholder: placeholder,
    value: state[name],
    onChange: (e) => handleInput(e.target.value),
    style: {
      width: width,
      padding: "6px 10px",
      paddingY: "7px",
      borderRadius: 5,
    },
  };

  useEffect(() => {
    if (error.length === 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [error, setDisableButton]);

  return (
    <>
      {" "}
      {rows ? (
        <textarea {...inputProps} rows={rows} autoComplete={"new-password"} />
      ) : (
        <input {...inputProps} autoComplete={"new-password "} />
      )}
      <Overlay
        target={target.current}
        show={error.length > 0}
        placement="bottom-start"
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              position: "absolute",
              backgroundColor: "rgba(255, 100, 100, 0.85)",
              padding: "2px 10px",
              color: "white",
              borderRadius: 3,
              ...props.style,
            }}
          >
            {error}
          </div>
        )}
      </Overlay>
    </>
  );
};

Input.defaultProps = {
  name: "",
  type: "text",
  placeholder: "",
  width: "50%",
  state: {},
  setState: () => undefined,
  rows: 0,
  validator: "",
  errorMessage: "",
  setDisableButton: () => undefined,
};

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf(["text", "number", "email", "password", "phone"]),
  width: PropTypes.string,
  placeholder: PropTypes.string,
  state: PropTypes.object,
  setState: PropTypes.func,
  rows: PropTypes.number,
  validator: PropTypes.string,
  errorMessage: PropTypes.string,
  setDisableButton: PropTypes.func,
};

export default Input;
