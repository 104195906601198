import React from "react";
import "./PrivacyPolicy.css";
import { Container, Col, Row } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <>
      <Container fluid>
        <Col>
          <Row className="px-5 mt-5">
            <h1>Privacy Policy</h1>
            <p>Updated at 2020-03-04</p>
            <p>
              SmartBoxDigital - FullStack development Agency (“we,” “our,” or
              “us”) is committed to protecting your privacy. This PrivacyPolicy
              explains how your personal information is collected, used, and
              disclosed by SmartBoxDigital - FullStack developmentAgency.
            </p>
            <p>
              This Privacy Policy applies to our website, and its associated
              subdomains (collectively, our “Service”) alongside our
              application,SmartBoxDigital - FullStack development Agency. By
              accessing or using our Service, you signify that you have
              read,understood, and agree to our collection, storage, use, and
              disclosure of your personal information as described in this
              PrivacyPolicy and our Terms of Service.
            </p>
            <h1>Definitions and key terms</h1>
            <p>
              To help explain things as clearly as possible in this Privacy
              Policy, every time any of these terms are referenced, are
              strictlydefined as:
            </p>
            <ul className="px-5">
              <li>
                Cookie: small amount of data generated by a website and saved by
                your web browser. It is used to identify your browser,provide
                analytics, remember information about you such as your language
                preference or login information.
              </li>
              <li>
                Company: when this policy mentions “Company,” “we,” “us,” or
                “our,” it refers to SmartBoxDigital, Magurii, 271, 427245,Rodna,
                Bistrita-Nasaud, Romania that is responsible for your
                information under this Privacy Policy.
              </li>
              <li>
                Country: where SmartBoxDigital - FullStack development Agency or
                the owners/founders of SmartBoxDigital - FullStackdevelopment
                Agency are based, in this case is Romania.
              </li>
              <li>
                Customer: refers to the company, organization or person that
                signs up to use the SmartBoxDigital - FullStack
                developmentAgency Service to manage the relationships with your
                consumers or service users.
              </li>
              <li>
                Device: any internet connected device such as a phone, tablet,
                computer or any other device that can be used to
                visitSmartBoxDigital - FullStack development Agency and use the
                services.
              </li>
              <li>
                IP address: Every device connected to the Internet is assigned a
                number known as an Internet protocol (IP) address.These numbers
                are usually assigned in geographic blocks. An IP address can
                often be used to identify the location fromwhich a device is
                connecting to the Internet.
              </li>
              <li>
                Personnel: refers to those individuals who are employed by
                SmartBoxDigital - FullStack development Agency or are
                undercontract to perform a service on behalf of one of the
                parties.
              </li>
              <li>
                Personal Data: any information that directly, indirectly, or in
                connection with other information — including a
                personalidentification number — allows for the identification or
                identifiability of a natural person.
              </li>
              <li>
                Service: refers to the service provided by SmartBoxDigital -
                FullStack development Agency as described in the relativeterms
                (if available) and on this platform.
              </li>
              <li>
                Third-party service: refers to advertisers, contest sponsors,
                promotional and marketing partners, and others who provideour
                content or whose products or services we think may interest you.
              </li>
              <li>
                Website: SmartBoxDigital - FullStack development Agency's site,
                which can be accessed via this
                URL:https://www.smartboxdigital.com
              </li>
              <li>
                You: a person or entity that is registered with SmartBoxDigital
                - FullStack development Agency to use the Services.
              </li>
            </ul>
            <h1>What Information Do We Collect?</h1>
            <p>
              We collect information from you when you visit our website,
              register on our site, place an order, subscribe to our
              newsletter,respond to a survey or fill out a form.
            </p>
            <ul className="px-5">
              <li>Name / Username</li>
              <li>Phone Numbers</li>
              <li>Email Addresses</li>
              <li>Mailing Addresses</li>
              <li>Job Titles</li>
              <li>Billing Addresses</li>
              <li>Age</li>
              <li>Password</li>
            </ul>
            <h1>How Do We Use The Information We Collect?</h1>
            <p>
              Any of the information we collect from you may be used in one of
              the following ways:
            </p>
            <ul className="px-5">
              <li>
                To personalize your experience (your information helps us to
                better respond to your individual needs)
              </li>
              <li>
                To improve our website (we continually strive to improve our
                website offerings based on the information and feedback
                wereceive from you)
              </li>
              <li>
                To improve customer service (your information helps us to more
                effectively respond to your customer service requests andsupport
                needs)
              </li>
              <li>To process transactions</li>
              <li>
                To administer a contest, promotion, survey or other site feature
              </li>
              <li>To send periodic emails</li>
            </ul>
            <h1>
              When does SmartBoxDigital - FullStack developmentAgency use end
              user information from third parties?
            </h1>
            <p>
              SmartBoxDigital - FullStack development Agency will collect End
              User Data necessary to provide the SmartBoxDigital -FullStack
              development Agency services to our customers.
            </p>
            <p>
              End users may voluntarily provide us with information they have
              made available on social media websites. If you provide us withany
              such information, we may collect publicly available information
              from the social media websites you have indicated. You cancontrol
              how much of your information social media websites make public by
              visiting these websites and changing your privacysettings.
            </p>
            <h1>
              When does SmartBoxDigital - FullStack developmentAgency use
              customer information from third parties?
            </h1>
            <p>
              We receive some information from the third parties when you
              contact us. For example, when you submit your email address tous
              to show interest in becoming a SmartBoxDigital - FullStack
              development Agency customer, we receive information from athird
              party that provides automated fraud detection services to
              SmartBoxDigital - FullStack development Agency. We
              alsooccasionally collect information that is made publicly
              available on social media websites. You can control how much of
              yourinformation social media websites make public by visiting
              these websites and changing your privacy settings.
            </p>
            <h1>Do we share the information we collect with thirdparties?</h1>
            <p>
              We may share the information that we collect, both personal and
              non-personal, with third parties such as advertisers,
              contestsponsors, promotional and marketing partners, and others
              who provide our content or whose products or services we think
              mayinterest you. We may also share it with our current and future
              affiliated companies and business partners, and if we are
              involvedin a merger, asset sale or other business reorganization,
              we may also share or transfer your personal and non-personal
              information to our successors-in-interest.
            </p>
            <p>
              We may engage trusted third party service providers to perform
              functions and provide services to us, such as hosting
              andmaintaining our servers and the website, database storage and
              management, e-mail management, storage marketing, creditcard
              processing, customer service and fulfilling orders for products
              and services you may purchase through the website. We willlikely
              share your personal information, and possibly some non-personal
              information, with these third parties to enable them toperform
              these services for us and for you.
            </p>
            <p>
              We may share portions of our log file data, including IP
              addresses, for analytics purposes with third parties such as
              webanalytics partners, application developers, and ad networks. If
              your IP address is shared, it may be used to estimate
              generallocation and other technographics such as connection speed,
              whether you have visited the website in a shared location, andtype
              of the device used to visit the website. They may aggregate
              information about our advertising and what you see on thewebsite
              and then provide auditing, research and reporting for us and our
              advertisers. We may also disclose personal andnon-personal
              information about you to government or law enforcement officials
              or private parties as we, in our sole discretion,believe necessary
              or appropriate in order to respond to claims, legal process
              (including subpoenas), to protect our rights andinterests or those
              of a third party, the safety of the public or any person, to
              prevent or stop any illegal, unethical, or legallyactionable
              activity, or to otherwise comply with applicable court orders,
              laws, rules and regulations.
            </p>
            <h1>
              Where and when is information collected fromcustomers and end
              users?
            </h1>
            <p>
              SmartBoxDigital - FullStack development Agency will collect
              personal information that you submit to us. We may also
              receivepersonal information about you from third parties as
              described above.
            </p>
            <h1>How Do We Use Your Email Address?</h1>
            <p>
              By submitting your email address on this website, you agree to
              receive emails from us. You can cancel your participation in anyof
              these email lists at any time by clicking on the opt-out link or
              other unsubscribe option that is included in the respective
              email.We only send emails to people who have authorized us to
              contact them, either directly, or through a third party. We do not
              sendunsolicited commercial emails, because we hate spam as much as
              you do. By submitting your email address, you also agree toallow
              us to use your email address for customer audience targeting on
              sites like Facebook, where we display custom advertisingto
              specific people who have opted-in to receive communications from
              us. Email addresses submitted only through the orderprocessing
              page will be used for the sole purpose of sending you information
              and updates pertaining to your order. If, however,you have
              provided the same email to us through another method, we may use
              it for any of the purposes stated in this Policy.Note: If at any
              time you would like to unsubscribe from receiving future emails,
              we include detailed unsubscribe instructions atthe bottom of each
              email.
            </p>
            <h1>How Long Do We Keep Your Information?</h1>
            <p>
              We keep your information only so long as we need it to provide
              SmartBoxDigital - FullStack development Agency to you andfulfill
              the purposes described in this policy. This is also the case for
              anyone that we share your information with and who carriesout
              services on our behalf. When we no longer need to use your
              information and there is no need for us to keep it to complywith
              our legal or regulatory obligations, we’ll either remove it from
              our systems or depersonalize it so that we can't identify you.
            </p>
            <h1>How Do We Protect Your Information?</h1>
            <p>
              We implement a variety of security measures to maintain the safety
              of your personal information when you place an order or enter,
              submit, or access your personal information. We offer the use of a
              secure server. All supplied sensitive/credit informationis
              transmitted via Secure Socket Layer (SSL) technology and then
              encrypted into our Payment gateway providers database onlyto be
              accessible by those authorized with special access rights to such
              systems, and are required to keep the informationconfidential.
              After a transaction, your private information (credit cards,
              social security numbers, financials, etc.) is never kept onfile.
              We cannot, however, ensure or warrant the absolute security of any
              information you transmit to SmartBoxDigital - FullStackdevelopment
              Agency or guarantee that your information on the Service may not
              be accessed, disclosed, altered, or destroyedby a breach of any of
              our physical, technical, or managerial safeguards.
            </p>
            <h1>Could my information be transferred to other countries?</h1>
            <p>
              SmartBoxDigital - FullStack development Agency is incorporated in
              Romania. Information collected via our website, throughdirect
              interactions with you, or from use of our help services may be
              transferred from time to time to our offices or personnel, orto
              third parties, located throughout the world, and may be viewed and
              hosted anywhere in the world, including countries thatmay not have
              laws of general applicability regulating the use and transfer of
              such data. To the fullest extent allowed byapplicable law, by
              using any of the above, you voluntarily consent to the
              trans-border transfer and hosting of such information.
            </p>
            <h1>
              Is the information collected through the SmartBoxDigital-
              FullStack development Agency Service secure?
            </h1>
            <p>
              We take precautions to protect the security of your information.
              We have physical, electronic, and managerial procedures tohelp
              safeguard, prevent unauthorized access, maintain data security,
              and correctly use your information. However, neitherpeople nor
              security systems are foolproof, including encryption systems. In
              addition, people can commit intentional crimes,make mistakes or
              fail to follow policies. Therefore, while we use reasonable
              efforts to protect your personal information, wecannot guarantee
              its absolute security. If applicable law imposes any
              non-disclaimable duty to protect your personal information,you
              agree that intentional misconduct will be the standards used to
              measure our compliance with that duty.
            </p>
            <h1>Can I update or correct my information?</h1>
            <p>
              The rights you have to request updates or corrections to the
              information SmartBoxDigital - FullStack development Agencycollects
              depend on your relationship with SmartBoxDigital - FullStack
              development Agency. Personnel may update or correcttheir
              information as detailed in our internal company employment
              policies.
            </p>
            <p>
              Customers have the right to request the restriction of certain
              uses and disclosures of personally identifiable information
              asfollows. You can contact us in order to (1) update or correct
              your personally identifiable information, (2) change your
              preferenceswith respect to communications and other information
              you receive from us, or (3) delete the personally identifiable
              informationmaintained about you on our systems (subject to the
              following paragraph), by cancelling your account. Such
              updates,corrections, changes and deletions will have no effect on
              other information that we maintain, or information that we
              haveprovided to third parties in accordance with this Privacy
              Policy prior to such update, correction, change or deletion. To
              protectyour privacy and security, we may take reasonable steps
              (such as requesting a unique password) to verify your identity
              beforegranting you profile access or making corrections. You are
              responsible for maintaining the secrecy of your unique password
              andaccount information at all times.
            </p>
            <p>
              You should be aware that it is not technologically possible to
              remove each and every record of the information you haveprovided
              to us from our system. The need to back up our systems to protect
              information from inadvertent loss means that acopy of your
              information may exist in a non-erasable form that will be
              difficult or impossible for us to locate. Promptly afterreceiving
              your request, all personal information stored in databases we
              actively use, and other readily searchable media will beupdated,
              corrected, changed or deleted, as appropriate, as soon as and to
              the extent reasonably and technically practicable.
            </p>
            <p>
              If you are an end user and wish to update, delete, or receive any
              information we have about you, you may do so by contacting the
              organization of which you are a customer.
            </p>
            <h1>Personnel</h1>
            <p>
              If you are a SmartBoxDigital - FullStack development Agency worker
              or applicant, we collect information you voluntarily provideto us.
              We use the information collected for Human Resources purposes in
              order to administer benefits to workers and screenapplicants.
            </p>
            <p>
              You may contact us in order to (1) update or correct your
              information, (2) change your preferences with respect
              tocommunications and other information you receive from us, or (3)
              receive a record of the information we have relating to you.Such
              updates, corrections, changes and deletions will have no effect on
              other information that we maintain, or information thatwe have
              provided to third parties in accordance with this Privacy Policy
              prior to such update, correction, change or deletion.
            </p>
            <h1>Sale of Business</h1>
            <p>
              We reserve the right to transfer information to a third party in
              the event of a sale, merger or other transfer of all or
              substantiallyall of the assets of SmartBoxDigital - FullStack
              development Agency or any of its Corporate Affiliates (as defined
              herein), or thatportion of SmartBoxDigital - FullStack development
              Agency or any of its Corporate Affiliates to which the Service
              relates, or inthe event that we discontinue our business or file a
              petition or have filed against us a petition in bankruptcy,
              reorganization orsimilar proceeding, provided that the third party
              agrees to adhere to the terms of this Privacy Policy.
            </p>
            <h1>Affiliates</h1>
            <p>
              We may disclose information (including personal information) about
              you to our Corporate Affiliates. For purposes of this
              PrivacyPolicy, "Corporate Affiliate" means any person or entity
              which directly or indirectly controls, is controlled by or is
              under commoncontrol with SmartBoxDigital - FullStack development
              Agency, whether by ownership or otherwise. Any information
              relating toyou that we provide to our Corporate Affiliates will be
              treated by those Corporate Affiliates in accordance with the terms
              of thisPrivacy Policy.
            </p>
            <h1>Governing Law</h1>
            <p>
              This Privacy Policy is governed by the laws of Romania without
              regard to its conflict of laws provision. You consent to
              theexclusive jurisdiction of the courts in connection with any
              action or dispute arising between the parties under or in
              connectionwith this Privacy Policy except for those individuals
              who may have rights to make claims under Privacy Shield, or the
              Swiss-USframework.
            </p>
            <p>
              The laws of Romania, excluding its conflicts of law rules, shall
              govern this Agreement and your use of the website. Your use ofthe
              website may also be subject to other local, state, national, or
              international laws.
            </p>
            <p>
              By using SmartBoxDigital - FullStack development Agency or
              contacting us directly, you signify your acceptance of this
              PrivacyPolicy. If you do not agree to this Privacy Policy, you
              should not engage with our website, or use our services. Continued
              use ofthe website, direct engagement with us, or following the
              posting of changes to this Privacy Policy that do not
              significantly affectthe use or disclosure of your personal
              information will mean that you accept those changes.
            </p>
            <h1>Your Consent</h1>
            <p>
              We've updated our Privacy Policy to provide you with complete
              transparency into what is being set when you visit our site andhow
              it's being used. By using our website, registering an account, or
              making a purchase, you hereby consent to our PrivacyPolicy and
              agree to its terms.
            </p>
            <h1>Links to Other Websites</h1>
            <p>
              This Privacy Policy applies only to the Services. The Services may
              contain links to other websites not operated or controlled
              bySmartBoxDigital - FullStack development Agency. We are not
              responsible for the content, accuracy or opinions expressed insuch
              websites, and such websites are not investigated, monitored or
              checked for accuracy or completeness by us. Pleaseremember that
              when you use a link to go from the Services to another website,
              our Privacy Policy is no longer in effect. Yourbrowsing and
              interaction on any other website, including those that have a link
              on our platform, is subject to that website’s ownrules and
              policies. Such third parties may use their own cookies or other
              methods to collect information about you.
            </p>
            <h1>Cookies</h1>
            <p>
              SmartBoxDigital - FullStack development Agency uses "Cookies" to
              identify the areas of our website that you have visited. ACookie
              is a small piece of data stored on your computer or mobile device
              by your web browser. We use Cookies to enhance theperformance and
              functionality of our website but are non-essential to their use.
              However, without these cookies, certainfunctionality like videos
              may become unavailable or you would be required to enter your
              login details every time you visit thewebsite as we would not be
              able to remember that you had logged in previously. Most web
              browsers can be set to disable theuse of Cookies. However, if you
              disable Cookies, you may not be able to access functionality on
              our website correctly or at all.We never place Personally
              Identifiable Information in Cookies.
            </p>
            <h1>Blocking and disabling cookies and similar technologies</h1>
            <p>
              Wherever you're located you may also set your browser to block
              cookies and similar technologies, but this action may block
              ouressential cookies and prevent our website from functioning
              properly, and you may not be able to fully utilize all of its
              featuresand services. You should also be aware that you may also
              lose some saved information (e.g. saved login details,
              sitepreferences) if you block cookies on your browser. Different
              browsers make different controls available to you. Disabling
              acookie or category of cookie does not delete the cookie from your
              browser, you will need to do this yourself from within
              yourbrowser, you should visit your browser's help menu for more
              information.
            </p>
            <h1>Kids' Privacy</h1>
            <p>
              We do not address anyone under the age of 13. We do not knowingly
              collect personally identifiable information from anyoneunder the
              age of 13. If You are a parent or guardian and You are aware that
              Your child has provided Us with Personal Data,please contact Us.
              If We become aware that We have collected Personal Data from
              anyone under the age of 13 withoutverification of parental
              consent, We take steps to remove that information from Our
              servers.
            </p>
            <h1>Changes To Our Privacy Policy</h1>
            <p>
              We may change our Service and policies, and we may need to make
              changes to this Privacy Policy so that they accuratelyreflect our
              Service and policies. Unless otherwise required by law, we will
              notify you (for example, through our Service) beforewe make
              changes to this Privacy Policy and give you an opportunity to
              review them before they go into effect. Then, if youcontinue to
              use the Service, you will be bound by the updated Privacy Policy.
              If you do not want to agree to this or any updated Privacy Policy,
              you can delete your account.
            </p>
            <h1>Third-Party Services</h1>
            <p>
              We may display, include or make available third-party content
              (including data, information, applications and other
              productsservices) or provide links to third-party websites or
              services ("Third- Party Services").
            </p>
            <p>
              You acknowledge and agree that SmartBoxDigital - FullStack
              development Agency shall not be responsible for any
              Third-PartyServices, including their accuracy, completeness,
              timeliness, validity, copyright compliance, legality, decency,
              quality or anyother aspect thereof. SmartBoxDigital - FullStack
              development Agency does not assume and shall not have any
              liability orresponsibility to you or any other person or entity
              for any Third-Party Services.
            </p>
            <p>
              Third-Party Services and links thereto are provided solely as a
              convenience to you and you access and use them entirely atyour own
              risk and subject to such third parties' terms and conditions.
            </p>
            <h1>Tracking Technologies</h1>
            <ul className="px-5">
              <li>Google Maps API</li>
              <p>
                Google Maps API is a robust tool that can be used to create a
                custom map, a searchable map, check-in functions, displaylive
                data synching with location, plan routes, or create a mashup
                just to name a few.
              </p>
              <p>
                Google Maps API may collect information from You and from Your
                Device for security purposes.
              </p>
              <p>
                Google Maps API collects information that is held in accordance
                with its Privacy Policy
              </p>
              <li>Cookies</li>
              <p>
                We use Cookies to enhance the performance and functionality of
                our website but are non-essential to their use. However,without
                these cookies, certain functionality like videos may become
                unavailable or you would be required to enter yourlogin details
                every time you visit the website as we would not be able to
                remember that you had logged in previously.
              </p>
              <li>Local Storage</li>
              <p>
                Local Storage sometimes known as DOM storage, provides web apps
                with methods and protocols for storing client-sidedata. Web
                storage supports persistent data storage, similar to cookies but
                with a greatly enhanced capacity and noinformation stored in the
                HTTP request header.
              </p>
            </ul>
            <h1>Information about General Data Protection Regulation(GDPR)</h1>
            <p>
              We may be collecting and using information from you if you are
              from the European Economic Area (EEA), and in this section ofour
              Privacy Policy we are going to explain exactly how and why is this
              data collected, and how we maintain this data underprotection from
              being replicated or used in the wrong way.
            </p>
            <h1>What is GDPR?</h1>
            <p>
              GDPR is an EU-wide privacy and data protection law that regulates
              how EU residents' data is protected by companies andenhances the
              control the EU residents have, over their personal data.
            </p>
            <p>
              The GDPR is relevant to any globally operating company and not
              just the EU-based businesses and EU residents. Ourcustomers’ data
              is important irrespective of where they are located, which is why
              we have implemented GDPR controls as ourbaseline standard for all
              our operations worldwide.
            </p>
            <h1>What is personal data?</h1>
            <p>
              Any data that relates to an identifiable or identified individual.
              GDPR covers a broad spectrum of information that could be usedon
              its own, or in combination with other pieces of information, to
              identify a person. Personal data extends beyond a person’sname or
              email address. Some examples include financial information,
              political opinions, genetic data, biometric data, IPaddresses,
              physical address, sexual orientation, and ethnicity.
            </p>
            <p>he Data Protection Principles include requirements such as:</p>
            <ul>
              <li>
                Personal data collected must be processed in a fair, legal, and
                transparent way and should only be used in a way that aperson
                would reasonably expect.
              </li>
              <li>
                Personal data should only be collected to fulfil a specific
                purpose and it should only be used for that purpose.
              </li>
              <p>
                Organizations must specify why they need the personal data when
                they collect it.
              </p>
              <li>
                Personal data should be held no longer than necessary to fulfil
                its purpose.
              </li>
              <li>
                People covered by the GDPR have the right to access their own
                personal data. They can also request a copy of their data,and
                that their data be updated, deleted, restricted, or moved to
                another organization.
              </li>
            </ul>
            <h1>Why is GDPR important?</h1>
            <p>
              GDPR adds some new requirements regarding how companies should
              protect individuals' personal data that they collect andprocess.
              It also raises the stakes for compliance by increasing enforcement
              and imposing greater fines for breach. Beyondthese facts it's
              simply the right thing to do. At SmartBoxDigital - FullStack
              development Agency we strongly believe that yourdata privacy is
              very important and we already have solid security and privacy
              practices in place that go beyond the requirementsof this new
              regulation.
            </p>
            <h1>
              Individual Data Subject's Rights - Data Access,Portability and
              Deletion
            </h1>
            <p>
              We are committed to helping our customers meet the data subject
              rights requirements of GDPR. SmartBoxDigital -
              FullStackdevelopment Agency processes or stores all personal data
              in fully vetted, DPA compliant vendors. We do store all
              conversationand personal data for up to 6 years unless your
              account is deleted. In which case, we dispose of all data in
              accordance with ourTerms of Service and Privacy Policy, but we
              will not hold it longer than 60 days.
            </p>
            <p>
              We are aware that if you are working with EU customers, you need
              to be able to provide them with the ability to access,
              update,retrieve and remove personal data. We got you! We've been
              set up as self service from the start and have always given
              youaccess to your data and your customers data. Our customer
              support team is here for you to answer any questions you mighthave
              about working with the API.
            </p>
            <h1>Contact Us</h1>
            <p>Don't hesitate to contact us if you have any questions.</p>
            <ul>
              <li>Via Email: contact@smartboxdigital.com</li>
              <li>Via Phone Number: +4 0746 812 739</li>
              <li>Via this Link: https://www.smartboxdigital.com/contact</li>
              <li>
                Via this Address: Magurii street, no. 271, postal code 427245,
                Rodna city, Bistrita-Nasaud county, Romania country
              </li>
            </ul>
          </Row>
        </Col>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
