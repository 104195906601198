import { request } from "../../api/config/axios-config";
import {
  postSubscribeRequest,
  postSubscribeSuccess,
  postSubscribeFailure,
} from "../types/subscribeTypes";

export const postSubscribe = (
  senderFirstName,
  senderLastName,
  senderEmail,
  setClear = () => undefined
) => {
  return (dispatch) => {
    dispatch(postSubscribeRequest());
    request
      .post("subscribe", {
        firstName: senderFirstName,
        lastName: senderLastName,
        email: senderEmail,
      })
      .then((res) => {
        const data = res?.data;
        dispatch(postSubscribeSuccess(data));
      })
      .catch((err) => {
        const data = err?.response?.data;
        dispatch(postSubscribeFailure(data));
      })
      .finally(() => {
        setClear();
      });
  };
};
