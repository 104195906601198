export const menuItems = [
  {
    value: "HOME",
    src: "/",
  },
  {
    value: "ABOUT",
    src: "/about",
  },
  {
    value: "CAREER",
    src: "/career",
  },
  {
    value: "SBD ACADEMY",
    src: "/courses-form",
  },
  // {
  //     value : 'PORTFOLIO',
  //     src : '/portfolio',
  // }
  {
    value: "SERVICES",
    src: "/services",
  },
];
