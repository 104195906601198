import React from "react";
import "./ConceptSection.css";
import { Container, Row, Col } from "react-bootstrap";
import whoFirst from "../../../../assets/ilustration/marketing.svg";
import whoSecond from "../../../../assets/ilustration/database-ilustration.svg";

const ConceptSection = () => {
  return (
    <Container fluid className="h-100 w-100 my-5 py-5 px-0 mx-0">
      <Row className="h-100 w-100 px-0 mx-0">
        <Col xs={12} id="ilustration-description" className="px-0 mx-0">
          <Row className=" my-5 justify-content-center px-0 mx-0">
            <h1 id="title-concept" className="text-center ">
              A concept that was started in 2015
            </h1>
          </Row>
        </Col>
        <Col xs={12} className="w-100 px-0 mx-0">
          <Row className="w-100 px-0 mx-0">
            <Col md={12} lg={6} id="ilustration-description">
              <p className="w-100 text-center description-hero px-3">
                SmartBoxDigital is not just a technology company, it's a concept
                that was started in 2015 with the goal of proving that
                developers are not just geeks working in a software factory -
                they are creators and representing one of the most important
                forces in the world transformation. Today, the SmartBoxDigital
                concept is supported and developed by two different entities,
                with different teams, goals and interests but working under the
                same principle - do our best for humanity.
              </p>
            </Col>
            <Col md={12} lg={6} className="px-0 mx-0">
              <img
                className="w-100 h-100 section-ilustration my-3 px-5"
                src={whoFirst}
                alt="First ilustration"
              />
            </Col>
          </Row>
          <Row className="d-flex flex-sm-column-reverse flex-md-row w-100 px-0 mx-0">
            <Col md={12} lg={6}>
              <img
                className="w-100 h-100 section-ilustration my-3 px-5"
                src={whoSecond}
                alt="Second ilustration"
              />
            </Col>
            <Col
              md={12}
              lg={6}
              id="ilustration-description"
              className="px-2 mx-0"
            >
              <p className="w-100 text-center description-hero px-3">
                An R&D and consultancy business focused on solutions in fintech,
                edutech, healthtech, e-commerce, IoT, AI/robotics and
                blockchain. The company is serving customers from different
                continents, like Europe and America. We are constantly looking
                to invest in startups, following our mission dedicated to
                innovation and research, while supporting the educational system
                in Romania, through company and employees personal
                contributions.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ConceptSection;
