import React from "react";
import "./App.css";
import Routing from "./utils/routing/Routing";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
