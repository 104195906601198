import React, { useState, useEffect } from "react";
import "./ContactUsForm.css";
import SbdButton from "../../button/button/SbdButton";
import { Col, Row, Container, Spinner } from "react-bootstrap";
import CardContainer from "../../container/card-container/CardContainer.jsx";
import Input from "../../input/Input";
import { useDispatch, useSelector } from "react-redux";
import { postFormular } from "../../../utils/redux/actions/formularAction";
import {
  clearError,
  clearFormular,
} from "../../../utils/redux/types/formularTypes";
import { namePattern, emailPattern } from "../../../utils/patterns/patterns";
import { clearContacts } from "../../../utils/redux/types/contactsTypes";
import { getContacts } from "../../../utils/redux/actions/contactsAction";

const ContactUsForm = () => {
  // ****************|| POST FORMULAR ||*********************

  const initialForm = { name: "", email: "", subject: "", message: "" };
  const [inputs, setInputs] = useState(initialForm);
  const [notification, setNotification] = useState(false);

  const dispatch = useDispatch();

  const message = useSelector((state) => state?.formularState?.error);
  const loading = useSelector((state) => state?.formularState?.loading);

  const loadingContacts = useSelector((state) => state?.contactsState?.loading);

  function sendFormData() {
    dispatch(
      postFormular(
        inputs.name,
        inputs.email,
        inputs.subject,
        inputs.message,
        () => setInputs(initialForm)
      )
    );
    setNotification(true);
  }

  // ***************************|| GET CONTACT DATA ||************************

  const [contacts, setContacts] = useState({});

  const contactsRedux = useSelector((state) => state?.contactsState);

  useEffect(() => {
    setContacts(contactsRedux?.contacts || {});
  }, [contactsRedux]);

  useEffect(() => {
    dispatch(getContacts());
    return () => dispatch(clearContacts());
  }, [dispatch]);

  // *****************|| CLEAR FORM DATA ||***********************

  useEffect(() => {
    return () => dispatch(clearFormular());
  }, [dispatch]);

  useEffect(() => {
    if (message)
      setTimeout(function () {
        dispatch(clearError());
      }, 3500);
    return;
  }, [dispatch, message]);

  return (
    <CardContainer maxHeight={1500} maxWidth={1300} padding={15}>
      <Container id="section-form">
        <Row className="mx-0 p-3 justify-content-center">
          <Col xs={12} lg={6}>
            <Row>
              <Col
                xs={12}
                className="d-flex align-items-center flex-column justify-content-sm-center"
              >
                <h1 className="text-center mb-3">
                  Want to get in touch? <br /> We’d love to hear from you.
                </h1>
              </Col>
            </Row>
            <Row className="d-flex align-items-center flex-column justify-content-sm-center">
              <Col xs={12} className="gradient-line">
                <br />
              </Col>
            </Row>
            <Row className="d-flex flex-start justify-content-center my-3">
              {loadingContacts ? (
                <Row className="m-0 p-0 justify-content-center">
                  {" "}
                  <Spinner animation="grow" size="sm" />
                </Row>
              ) : (
                <Col xs={12} className="text-center">
                  <p className="w-100 my-1">{contacts?.emails?.contactEmail}</p>
                  <p className="w-100 my-2">{contacts?.emails?.officeEmail}</p>
                  <p className="w-100 my-1">
                    {contacts?.phone?.phoneNumber0} <br />{" "}
                    {contacts?.phone?.phoneNumber1}
                  </p>
                  <p className="w-100 my-2">
                    {contacts?.location?.address?.city}{" "}
                    {contacts?.location?.address?.street}{" "}
                    {contacts?.location?.address?.streetNumber}{" "}
                    {contacts?.location?.address?.country}{" "}
                  </p>
                  <p className="w-100 my-1">
                    {contacts?.schedule?.week} <br />{" "}
                    {contacts?.schedule?.weekend}
                  </p>
                </Col>
              )}
            </Row>
            <Row className="justify-content-center">
              <Col xs={12}>
                <ul className="list-group list-group-horizontal mx-0 px-0 align-items-center justify-content-center">
                  <li className="list-group-item border-0">
                    <a
                      href={"https://www.facebook.com/smartboxdigital"}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Facebook"
                    >
                      <i className="bi bi-facebook icon" />
                    </a>
                  </li>
                  <li className="list-group-item border-0 ">
                    <a
                      href={`https://www.linkedin.com/company/smart-box-digital/`}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Linkedin"
                    >
                      <i className="bi bi-linkedin icon" />
                    </a>
                  </li>
                  <li className="list-group-item border-0">
                    <a
                      href={`https://www.instagram.com/smartboxdigital/`}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Instagram"
                    >
                      <i className="bi bi-instagram icon " />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row
              className={` align-items-center flex-column justify-content-sm-center my-3 ${
                window.innerWidth < 992 ? "d-flex" : "d-none"
              } `}
            >
              <Col xs={12} className="gradient-line">
                <br />
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6} className="p-0 m-0">
            <Row>
              <Col xs={12} className="my-3 py-2 px-0 text-center">
                <h2>Or you can also contact us here</h2>
              </Col>
            </Row>
            <Row className="mt-lg-0 mt-2 justify-content-center">
              <form className="w-100 p-0 m-0 d-flex gap-3 align-items-center justify-content-center flex-column">
                <Col xs={12} md={8}>
                  <Input
                    name={"name"}
                    type={"text"}
                    placeholder={"Name"}
                    errorMessage={
                      "Enter a valid name (Only letters, min 3 characters)"
                    }
                    state={inputs}
                    setState={setInputs}
                    width={"100%"}
                  />
                </Col>
                <Col xs={12} md={8}>
                  <Input
                    name={"email"}
                    type={"email"}
                    placeholder={"Email"}
                    state={inputs}
                    setState={setInputs}
                    width={"100%"}
                  />
                </Col>
                <Col xs={12} md={8}>
                  <Input
                    name={"subject"}
                    type={"text"}
                    placeholder={"Subject"}
                    validator={`([^<>$]){4,}$`}
                    errorMessage={`Subject can't contain <, > ,$ and need to be at least 4 characters`}
                    state={inputs}
                    setState={setInputs}
                    width={"100%"}
                  />
                </Col>
                <Col xs={12} md={8}>
                  <Input
                    name={"message"}
                    type={"text"}
                    placeholder={"Message"}
                    state={inputs}
                    setState={setInputs}
                    rows={3}
                    width={"100%"}
                  ></Input>
                </Col>
                <Row className="mt-4 justify-content-center position-relative"></Row>
                <SbdButton
                  width={250}
                  title={
                    !loading ? "Send" : <Spinner animation="grow" size="sm" />
                  }
                  disabled={
                    !inputs.name ||
                    !inputs.email ||
                    !inputs.subject ||
                    !inputs.message ||
                    !namePattern.test(inputs.name) ||
                    !emailPattern.test(inputs.email)
                  }
                  variant={"primary"}
                  handleClick={sendFormData}
                />
                <div
                  className={`notification  text-center justify-content-center ${
                    notification ? "d-flex" : "d-none"
                  } ${message?.status ? "red-error" : "gray-error"}`}
                >
                  {message?.message}
                </div>
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
    </CardContainer>
  );
};

export default ContactUsForm;
