import React from "react";
import Footer from "../../components/footer/Footer.jsx";
import Header from "../../components/header/Header.jsx";
import About from "../../pages/about/About.jsx";
import Career from "../../pages/career/Career.jsx";
import Contact from "../../pages/contact/Contact.jsx";
import Home from "../../pages/home/Home.jsx";
import Services from "../../pages/services/Services.jsx";
// import Portfolio from "../../pages/portfolio/Portfolio.jsx";
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage.jsx";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy.jsx";
import CoursesForm from "../../pages/courseForm/CourseForm.jsx";
import TopButton from "../../components/button/button/topButton/TopButton.jsx";
import { Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import ScrollToTop from "./ScrollToTop.jsx";

const Routing = () => {
  return (
    <>
      <Header />
      <Container fluid className="p-0 m-0">
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/career" element={<Career />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route
                            path="/portfolio"
                            element={<Portfolio />}
                        /> */}
            <Route path="/services" element={<Services />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/courses-form" element={<CoursesForm />} />
            <Route path="/*" element={<NotFoundPage />} />
          </Routes>
        </ScrollToTop>
        <TopButton />
      </Container>
      <Footer />
    </>
  );
};

export default Routing;
