export const itemsAdvantages = [
  {
    advantage: `High demand of software developer.`,
    description: `According to the Bureau of Labor Statistics, ‘there are going to be about 189,200 new openings for software developers, QA analysts, and testers each year between 2020 and 2030 only in the US.`,
    link: `https://intersog.com/blog/software-engineer-demand/`,
  },
  {
    advantage: `Flexible work jobs, working remotly.`,
    description: `Working from home is a lot of people’s dream, but as a developer you can do much more than that. All you need is a computer and an internet connection,
            so you’re not even restricted to your home! You can literally go anywhere in the world and still work.`,
    link: `https://www.monster.com/career-advice/article/flexible-jobs-in-tech`,
  },
  {
    advantage: `Flexible work schedule.`,
    description: `The most common perk of flexible jobs is a unique work schedule that doesn’t conform to the typical 9-5 mentality. Whether it means making your own schedule,
            or being able to work out of a set timeframe, it allows employees to schedule their work around their personal needs.`,
    link: `https://www.youtube.com/watch?v=aCeAFelWAq0&ab_channel=ManeleMentolate`,
  },
  {
    advantage: `The rate of pay is very good.`,
    description: `According to SalaryExpert and Payscale, the average software engineer’s salary in the Europe ranges from $22,000 up to $30,000 per year.`,
    link: `https://qubit-labs.com/average-software-developer-salaries-salary-comparison-country/`,
  },
  {
    advantage: `Teamwork In Software Development.`,
    description: `Benefits of teamwork in software development can be measured, qualitatively and quantitatively.Think about it: Discipline. Confidence. Transparency. Trust. Morale.
             Let’s see why teamwork is one of the greatest assets for success.`,
    link: `https://gitential.com/teamwork-in-software-development-8-benefits-of-building-up-a-collaborative-team/`,
  },
  {
    advantage: `Possibility to advance fast.`,
    description: ``,
    link: ``,
  },
  {
    advantage: `Possibility to be recruited in our company.`,
    description: ``,
    link: ``,
  },
  {
    advantage: `We will help you to create an amazing CV.`,
    description: ``,
    link: ``,
  },
  {
    advantage: `We will recommend you to top IT recruiters.`,
    description: `To make sure the probability of you being hired after our courses, we will recomand you to our collaborators and top IT recruiters.`,
    link: ``,
  },
  {
    advantage: `We will help you with advice and tips until you find a job.`,
    description: ``,
    link: ``,
  },
];
