import React from "react";
import "./HomeLeaveEmailSection.css";
import SbdButton from "../../../../components/button/button/SbdButton.jsx";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const HomeLeaveEmailSection = () => {
  const navigate = useNavigate();

  return (
    <Container fluid id="home-email-section">
      <Row
        className="justify-content-center align-items-center m-0 p-0"
        id="continer-email"
      >
        <Col xs={12} md={8}>
          <Row className="align-items-center">
            <Col
              md={6}
              xs={12}
              className="d-flex justify-content-center align-items-center px-5"
            >
              <h2 id="title-home-email" className="text-center">
                Book A Free Digital Marketing Consultation
              </h2>
            </Col>
            <Col
              md={4}
              xs={12}
              className="d-flex flex-column align-items-center gap-3"
            >
              <SbdButton
                id={"send-adress"}
                title="Book my free meeting"
                handleClick={() => navigate("/contact")}
                variant={"secondary"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeLeaveEmailSection;
