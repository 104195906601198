// types
export const POST_APPLY_REQUEST = "POST_APPLY_REQUEST";
export const POST_APPLY_SUCCESS = "POST_APPLY_SUCCESS";
export const POST_APPLY_FAILURE = "POST_APPLY_FAILURE";
export const CLEAR_APPLY = "CLEAR_APPLY";
export const CLEAR_ERROR = "CLEAR_ERROR";

// actions
export const postApplyRequest = () => {
  return {
    type: POST_APPLY_REQUEST,
  };
};

export const postApplySuccess = (response) => {
  return {
    type: POST_APPLY_SUCCESS,
    payload: response,
  };
};

export const postApplyFailure = (error) => {
  return {
    type: POST_APPLY_FAILURE,
    payload: error,
  };
};

export const clearApply = () => {
  return {
    type: CLEAR_APPLY,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};
