import {
  POST_APPLY_REQUEST,
  POST_APPLY_SUCCESS,
  POST_APPLY_FAILURE,
  CLEAR_APPLY,
  CLEAR_ERROR,
} from "../types/applyTypes.js";

let initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
  },
};

const applyReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_APPLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_APPLY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          status: false,
          message: action?.payload?.message,
        },
      };
    case POST_APPLY_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          message: action?.payload?.error,
        },
      };
    case CLEAR_APPLY:
      return {
        ...initialState,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {
          status: false,
          message: "",
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default applyReducer;
