import {
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  CLEAR_PROJECTS,
} from "../types/projectsTypes.js";
import {
  GET_PROJECT_REQUEST,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILURE,
  CLEAR_PROJECT,
} from "../types/projectsTypes.js";

const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
  },
  projects: [],
  project: {},
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          status: false,
          message: action?.payload?.message,
        },
        projects: action?.payload?.projects,
      };
    case GET_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          message: action?.payload?.message,
        },
      };
    case GET_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          status: false,
          message: action?.payload?.message,
        },
        project: action?.payload?.project,
      };
    case GET_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          message: action?.payload?.message,
        },
      };
    case CLEAR_PROJECTS:
      return {
        ...initialState,
      };
    case CLEAR_PROJECT:
      return {
        ...state,
        error: { ...initialState.error },
        project: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export default projectsReducer;
