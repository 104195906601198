import React from "react";
import "./RenderTestimonial.css";
import testimonialIlustration from "../../../../../assets/ilustration/testimonial-ilustration.svg";
import Carousel from "react-bootstrap/Carousel";
import { itemsTestimonial } from "../../../../../utils/data/itemsTestimonial.js";
import CardContainer from "../../../../../components/container/card-container/CardContainer.jsx";

const RenderTestimonial = () => {
  return (
    <>
      <Carousel variant="dark" className="align-items-center">
        {itemsTestimonial.map((item, index) => {
          return (
            <Carousel.Item key={`carousel-home-item-${index}`}>
              <div className="w-100 h-100 d-flex justify-content-center">
                <CardContainer
                  id="continer-testimonial"
                  minWidth={250}
                  minHeight={400}
                  maxWidth={1000}
                >
                  <div className="d-flex justify-content-center align-item-center w-25">
                    <img
                      className="w-75 h-auto"
                      src={testimonialIlustration}
                      alt="Slide"
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center w-75">
                    <h3 className="text-center w-75 h-50 d-flex align-items-center text-testimonial">
                      {item.testimonial}
                    </h3>
                    <p className="w-75 author-testimonial">{item.author}</p>
                  </div>
                </CardContainer>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
};

export default RenderTestimonial;
