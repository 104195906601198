//types
export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";
export const CLEAR_PROJECTS = "CLEAR_PROJECTS";

//actions
export const getProjectsRequest = () => {
  return {
    type: GET_PROJECTS_REQUEST,
  };
};

export const getProjectsSuccess = (payload) => {
  return {
    type: GET_PROJECTS_SUCCESS,
    payload: payload,
  };
};

export const getProjectsFailure = (payload) => {
  return {
    type: GET_PROJECTS_FAILURE,
    payload: payload,
  };
};

export const clearProjescts = () => {
  return {
    type: CLEAR_PROJECTS,
  };
};

//types
export const GET_PROJECT_REQUEST = "GET_PROJECT_REQUEST";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILURE = "GET_PROJECT_FAILURE";
export const CLEAR_PROJECT = "CLEAR_PROJECT";

//actions
export const getProjectRequest = () => {
  return {
    type: GET_PROJECT_REQUEST,
  };
};

export const getProjectSuccess = (payload) => {
  return {
    type: GET_PROJECT_SUCCESS,
    payload: payload,
  };
};

export const getProjectFailure = (payload) => {
  return {
    type: GET_PROJECT_FAILURE,
    payload: payload,
  };
};

export const clearProject = () => {
  return {
    type: CLEAR_PROJECT,
  };
};
