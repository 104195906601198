import {
  POST_SUBSCRIBE_REQUEST,
  POST_SUBSCRIBE_SUCCESS,
  POST_SUBSCRIBE_FAILURE,
} from "../types/subscribeTypes.js";

let initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
  },
};

const subscribeReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_SUBSCRIBE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          status: false,
          message: action?.payload?.message,
        },
      };
    case POST_SUBSCRIBE_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          message: action?.payload?.error,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default subscribeReducer;
