import React from "react";
import "./Career.css";
import { Container } from "react-bootstrap";
import CareerHeroSection from "./careerComponent/careerHeroSection/CareerHeroSection.jsx";
import CareerWhyRemoteSection from "./careerComponent/careerWhyRemoteSection/CareerWhyRemoteSection.jsx";
import CareerEndSection from "./careerComponent/careerEndSection/CareerEndSection.jsx";

const Career = () => {
  return (
    <Container>
      <CareerHeroSection />
      <CareerWhyRemoteSection />
      <CareerEndSection />
    </Container>
  );
};

export default Career;
