import React, { useState } from "react";
import "./SecondSection.css";
import { Col, Row } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { useNavigate } from "react-router-dom";

const SecondSection = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const footerMenuLinks = () => {
    return (
      <>
        <Row xs={12} className="mb-2">
          <p
            className="w-100 text-center pointer"
            onClick={() => navigate("/")}
          >
            Home
          </p>
        </Row>
        <Row xs={12} className="mb-2">
          <p
            className="w-100 text-center pointer"
            onClick={() => navigate("/about")}
          >
            Our Specialists
          </p>
        </Row>
        <Row xs={12} className="mb-2">
          <p
            className="w-100 text-center pointer"
            onClick={() => navigate("/career")}
          >
            Career
          </p>
        </Row>
        <Row xs={12} className="mb-2">
          <p
            className="w-100 text-center pointer"
            onClick={() => navigate("/contact")}
          >
            Contact Us
          </p>
        </Row>
        <Row xs={12} className="mb-2">
          <p
            className="w-100 text-center pointer"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy & Policy
          </p>
        </Row>
      </>
    );
  };

  return (
    <Col className="second-section d-flex justify-content-center text-center">
      {window.innerWidth <= 600 ? (
        <Row className="d-flex justify-content-center px-0">
          <div className=" d-flex justify-content-center">
            <button
              className="company-button px=- mx-0"
              onClick={() => setOpen(!open)}
            >
              <h2 className="text-center mb-4 px-0 mx-0 w-50">Company</h2>
            </button>
          </div>
          <Collapse in={open}>
            <Col className="text-center">{footerMenuLinks()}</Col>
          </Collapse>
        </Row>
      ) : (
        <Row className="d-flex justify-content-center px-0">
          <div className=" text-center">
            <h2 className="text-center mb-4 px-0 mx-0 w-100">Company</h2>
          </div>
          <Col className="text-center">{footerMenuLinks()}</Col>
        </Row>
      )}
    </Col>
  );
};

export default SecondSection;
