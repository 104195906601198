import React, { useState, useEffect } from "react";
import CardContainer from "../../../components/container/card-container/CardContainer";
import SbdButton from "../../../components/button/button/SbdButton";
import { Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getServices } from "../../../utils/redux/actions/servicesAction";
import { clearServices } from "../../../utils/redux/types/servicesTypes";
import Service from "../../../assets/images/service.png";

const Cards = () => {
  const [services, setServices] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const servicesRedux = useSelector((state) => state?.servicesState);
  const loading = servicesRedux?.loading;
  const error = servicesRedux?.error?.status;

  useEffect(() => {
    setServices(servicesRedux?.services || []);
  }, [servicesRedux]);

  useEffect(() => {
    dispatch(getServices());
    return () => dispatch(clearServices());
  }, [dispatch]);
  return (
    <>
      {loading ? (
        <Row className="justify-content-center my-5 py-5 w-100 align-items-center">
          <Spinner animation="border" role="status" variant="light" />
        </Row>
      ) : error ? (
        <Row className="justify-content-center bg-light text-danger error-message text-center my-5 py-5 w-100">
          OOpss... There was a problem, please try again later.
        </Row>
      ) : (
        services.map((service, index) => {
          return (
            <div
              key={index}
              style={{
                padding: "24px",
                display: "flex",
                marginTop: index % 2 === 0 ? 25 : 75,
                marginBottom: 25,
              }}
            >
              <CardContainer
                minHeight={450}
                minWidth={250}
                maxWidth={500}
                maxHeight={3500}
                padding={10}
              >
                <div className="w-100 d-flex flex-column justify-content-between text-center h-100 p-4">
                  <div className="w-100">
                    <img
                      src={
                        service?.servicePhoto &&
                        service?.servicePhoto !== "servicePhoto"
                          ? service?.servicePhoto
                          : Service
                      }
                      alt={`${service?._id}-img`}
                      className="mb-4 w-100 services-card-avatar"
                    />
                    <h2 className="mb-1">{service?.serviceName}</h2>
                    <div className="gradient-line mb-3"></div>
                    <p className="mb-5 services-card-description">
                      {" "}
                      {service?.serviceDescription}
                    </p>
                  </div>
                  <SbdButton
                    id={`${service?._id}-button`}
                    width={"100%"}
                    title={"Book a free 30 minute meeting"}
                    handleClick={() => navigate("/contact")}
                    className="w-100"
                  />
                </div>
              </CardContainer>
            </div>
          );
        })
      )}
    </>
  );
};

export default Cards;
