import {
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE,
  CLEAR_SERVICES,
} from "../types/servicesTypes.js";

const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
  },
  services: [],
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          status: false,
          message: action?.payload?.message,
        },
        services: action?.payload?.services,
      };
    case GET_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          message: action?.payload?.message,
        },
      };
    case CLEAR_SERVICES:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};
export default servicesReducer;
