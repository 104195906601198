//types
export const GET_SERVICES_REQUEST = "GET_SERVICES_REQUEST";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAILURE = "GET_SERVICES_FAILURE";
export const CLEAR_SERVICES = "CLEAR_SERVICES";

//actions
export const getServicesRequest = () => {
  return {
    type: GET_SERVICES_REQUEST,
  };
};

export const getServicesSuccess = (payload) => {
  return {
    type: GET_SERVICES_SUCCESS,
    payload: payload,
  };
};

export const getServicesFailure = (payload) => {
  return {
    type: GET_SERVICES_FAILURE,
    payload: payload,
  };
};
export const clearServices = () => {
  return {
    type: CLEAR_SERVICES,
  };
};
