import { request } from "../../api/config/axios-config";
import {
  getContactsRequest,
  getContactsSuccess,
  getContactsFailure,
} from "../types/contactsTypes";

export const getContacts = () => {
  return (dispatch) => {
    dispatch(getContactsRequest());
    request
      .get("get-contacts/all")
      .then((res) => {
        const data = res?.data;
        dispatch(getContactsSuccess(data));
      })
      .catch((err) => {
        const data = err?.data;
        dispatch(getContactsFailure(data));
      });
  };
};
