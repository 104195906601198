import { request } from "../../api/config/axios-config";
import {
  postApplyRequest,
  postApplySuccess,
  postApplyFailure,
} from "../types/applyTypes";

export const postApply = (data, setClear = () => undefined) => {
  return (dispatch) => {
    dispatch(postApplyRequest());
    request
      .post("apply", {
        ...data,
      })
      .then((res) => {
        const data = res?.data;
        dispatch(postApplySuccess(data));
      })
      .catch((err) => {
        const data = err?.response?.data;
        dispatch(postApplyFailure(data));
      })
      .finally(() => {
        setClear();
      });
  };
};
