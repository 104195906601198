import React from "react";
import "./SbdButton.css";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const SbdButton = (props) => {
  const { id, title, width, handleClick, variant, disabled } = props;

  return (
    <Button
      id={id}
      onClick={handleClick}
      style={{ minWidth: width || 100 }}
      className={disabled ? "disabled" : variant}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

SbdButton.defaultProps = {
  id: "",
  title: "",
  handleClick: () => undefined,
  variant: "primary",
  disabled: false,
};

SbdButton.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["primary", "secondary", "disabled"]),
  disabled: PropTypes.bool,
};

export default SbdButton;
