import { combineReducers } from "redux";
import membersReducer from "./membersReducer";
import projectsReducer from "./projectsReducer";
import formularReducer from "./formularReducer";
import likeReducer from "./likeReducer";
import postsReducer from "./postsReducer";
import subscribeReducer from "./subscribeReducer";
import contactsReducer from "./contactsReducer";
import servicesReducer from "./servicesReducer";
import applyReducer from "./applyReducer";

const RootReducer = combineReducers({
  projectsState: projectsReducer,
  membersState: membersReducer,
  postsState: postsReducer,
  formularState: formularReducer,
  likeState: likeReducer,
  subscribeState: subscribeReducer,
  contactsState: contactsReducer,
  servicesState: servicesReducer,
  applyState: applyReducer,
});

export default RootReducer;
