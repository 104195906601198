import { request } from "../../api/config/axios-config.js";
import {
  postLikeRequest,
  postLikeSuccess,
  postLikeFailure,
} from "../types/likeTypes.js";

export const postLike = (postId) => {
  return (dispatch) => {
    dispatch(postLikeRequest());
    request
      .patch("like-post", { postId: postId })
      .then((res) => {
        const data = res?.data;
        dispatch(postLikeSuccess(data));
      })
      .catch((err) => {
        const data = err?.data;
        dispatch(postLikeFailure(data));
      });
  };
};
