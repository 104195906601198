// types
export const GET_POSTS_REQUEST = "GET_POSTS_REQUEST";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAILURE = "GET_POSTS_FAILURE";
export const CLEAR_POSTS = "CLEAR_POSTS";
export const CLEAR_POST = "CLEAR_POST";

// ACTIONS
export const getPostsRequest = () => {
  return {
    type: GET_POSTS_REQUEST,
  };
};

export const getPostsSuccess = (payload) => {
  return {
    type: GET_POSTS_SUCCESS,
    payload: payload,
  };
};

export const getPostsFailure = (payload) => {
  return {
    type: GET_POSTS_FAILURE,
    payload: payload,
  };
};

export const clearPosts = () => {
  return {
    type: CLEAR_POSTS,
  };
};

// types
export const GET_POST_REQUEST = "GET_POST_REQUEST";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const GET_POST_FAILURE = "GET_POST_FAILURE";

// ACTIONS
export const getPostRequest = () => {
  return {
    type: GET_POST_REQUEST,
  };
};

export const getPostSuccess = (payload) => {
  return {
    type: GET_POST_SUCCESS,
    payload: payload,
  };
};

export const getPostFailure = (payload) => {
  return {
    type: GET_POST_FAILURE,
    payload: payload,
  };
};

export const clearPost = () => {
  return {
    type: CLEAR_POST,
  };
};
