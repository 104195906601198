import React, { useState, useEffect } from "react";
import SbdButton from "../../components/button/button/SbdButton";
import BoxHeart from "../../assets/images/boxheart.svg";
import { Row, Col, Image } from "react-bootstrap";
import "./SubscribeSection.css";
import Input from "../input/Input";
import Collapse from "react-bootstrap/Collapse";
import { Spinner } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import { postSubscribe } from "../../utils/redux/actions/subscribeAction";
import { useDispatch, useSelector } from "react-redux";
import { namePattern, emailPattern } from "../../utils/patterns/patterns";

const SubscribeSection = () => {
  const initialSubscribe = { firstName: "", lastName: "", email: "" };
  const [inputs, setInputs] = useState(initialSubscribe);

  const dispatch = useDispatch();

  const loading = useSelector((state) => state?.subscribeState?.loading);
  const message = useSelector((state) => state?.subscribeState?.error);
  const [notification, setNotification] = useState(false);

  const [open, setOpen] = useState(true);

  function sendSubscribeFormData() {
    dispatch(
      postSubscribe(inputs.firstName, inputs.lastName, inputs.email, () =>
        setInputs(initialSubscribe)
      )
    );
    setNotification(true);
  }

  useEffect(() => {
    if (message?.message === "SmartBoxDigital thanks you for subscribing.")
      setTimeout(function () {
        setOpen(false);
      }, 2000);
  }, [message]);

  return (
    <>
      <Collapse in={open}>
        <Row className="m-0 p-0">
          <div className="d-flex justify-content-end w-100 px-2">
            <X
              size={60}
              onClick={() => setOpen(false)}
              className={"cursor-pointer"}
            />
          </div>
          <Row className="subscribeSection align-items-center d-flex justify-content-around my-5">
            <Col
              xs={12}
              md={6}
              className={"d-flex flex-column align-items-center"}
            >
              <h2 className="my-4 text-center">Why Smart Box Digital?</h2>
              <p className="mb-4 pb-4 text-center description-subscribe">
                Find out. Subscribe to our newsletter.
              </p>
              <div className={"d-flex justify-content-center w-100 mb-3"}>
                <Input
                  name={"firstName"}
                  type={"text"}
                  placeholder={"First Name"}
                  width={"30%"}
                  state={inputs}
                  setState={setInputs}
                  errorMessage={
                    "Enter a valid name (Only letters, min 3 characters)"
                  }
                />
                <div style={{ width: "5%" }}></div>
                <Input
                  name={"lastName"}
                  type={"text"}
                  placeholder={"Last Name"}
                  width={"30%"}
                  state={inputs}
                  setState={setInputs}
                  errorMessage={
                    "Enter a valid name (Only letters, min 3 characters)"
                  }
                />
              </div>
              <Input
                name={"email"}
                type={"email"}
                placeholder={"Email"}
                width={"65%"}
                state={inputs}
                setState={setInputs}
              />
              <Row className="mt-3 justify-content-center">
                <SbdButton
                  name={"Subscribe-section-button"}
                  title={
                    !loading ? (
                      "Subscribe"
                    ) : (
                      <Spinner animation="grow" size="sm" />
                    )
                  }
                  disabled={
                    !inputs.firstName ||
                    !inputs.lastName ||
                    !inputs.email ||
                    !namePattern.test(inputs.firstName) ||
                    !emailPattern.test(inputs.email)
                  }
                  variant={"primary"}
                  handleClick={() => {
                    sendSubscribeFormData();
                  }}
                ></SbdButton>
                <div
                  className={`notification mt-3 text-center justify-content-center ${
                    notification ? "d-flex" : "d-none"
                  } ${message?.status ? "red-error" : "gray-error"}`}
                >
                  {message?.message}
                </div>
              </Row>
            </Col>
            <Col xs={12} md={6} className="my-5 d-flex justify-content-center">
              <Image
                fluid
                src={BoxHeart}
                className="w-50 h-auto"
                alt="ilustration-subscribe"
              />
            </Col>
          </Row>
        </Row>
      </Collapse>
    </>
  );
};

export default SubscribeSection;
