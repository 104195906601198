import { request } from "../../api/config/axios-config";
import {
  postFormularRequest,
  postFormularSuccess,
  postFormularFailure,
} from "../types/formularTypes";

export const postFormular = (
  senderName,
  senderEmail,
  senderSubject,
  senderMessage,
  setClear = () => undefined
) => {
  return (dispatch) => {
    dispatch(postFormularRequest());
    request
      .post("contact-academy", {
        senderName: senderName,
        senderEmail: senderEmail,
        senderSubject: senderSubject,
        senderMessage: senderMessage,
      })
      .then((res) => {
        const data = res?.data;
        dispatch(postFormularSuccess(data));
      })
      .catch((err) => {
        const data = err?.data;
        dispatch(postFormularFailure(data));
      })
      .finally(() => {
        setClear();
      });
  };
};
