import React from "react";
import "./RenderRemoteCard.css";
import CardContainer from "../../../../../components/container/card-container/CardContainer.jsx";
import { Row, Col } from "react-bootstrap";
import { itemRemoteCard } from "../../../../../utils/data/itemRemoteCard.js";

const RenderRemoteCard = () => {
  return (
    <>
      {itemRemoteCard.map((item, index) => {
        return (
          <Col
            key={`remote-card-${index}`}
            md={8}
            xs={12}
            className="mx-0 my-3 px-0 py-3"
          >
            <CardContainer
              id={`card-remote-${index}`}
              minWidth={280}
              maxWidth={1300}
              minHeight={300}
              maxHeight={2000}
            >
              <Row className="h-100 w-100 m-0 p-0 justify-content-center">
                <Row className=" my-2 justify-content-center">
                  <Col
                    xs={12}
                    md={6}
                    className="m-0 pt-3 px-0 d-flex align-items-center"
                    id="remote-card-title"
                  >
                    <h1 id="title-remote-card">{item.title}</h1>
                  </Col>
                  <Col xs={12} md={6}>
                    <img
                      className="w-100 h-100 section-ilustration-remote-card"
                      src={item.image}
                      alt="astronaut"
                    />
                  </Col>
                </Row>
                <Row>
                  <div className="gradient-line mb-4"></div>
                  <p className="w-100 text-center description-remote-card mx-0 px-1">
                    {item.content}
                  </p>
                </Row>
              </Row>
            </CardContainer>
          </Col>
        );
      })}
    </>
  );
};

export default RenderRemoteCard;
