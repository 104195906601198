import { request } from "../../api/config/axios-config";
import {
  getMembersRequest,
  getMembersSuccess,
  getMembersFailure,
} from "../types/membersTypes";

export const getMembers = () => {
  return (dispatch) => {
    dispatch(getMembersRequest());
    request
      .get("get-members/all?items=13&page=1")
      .then((res) => {
        const data = res?.data;
        dispatch(getMembersSuccess(data));
      })
      .catch((err) => {
        const data = err?.data;
        dispatch(getMembersFailure(data));
      });
  };
};
