import { request } from "../../api/config/axios-config";
import {
  getServicesRequest,
  getServicesSuccess,
  getServicesFailure,
} from "../types/servicesTypes";

export const getServices = () => {
  return (dispatch) => {
    dispatch(getServicesRequest());
    request
      .get("get-services/all")
      .then((res) => {
        const data = res?.data;
        dispatch(getServicesSuccess(data));
      })
      .catch((err) => {
        const data = err?.data;
        dispatch(getServicesFailure(data));
      });
  };
};
