//types
export const GET_CONTACTS_REQUEST = "GET_CONTACTS_REQUEST";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_FAILURE = "GET_CONTACTS_FAILURE";
export const CLEAR_CONTACTS = "CLEAR_CONTACTS";

//actions
export const getContactsRequest = () => {
  return {
    type: GET_CONTACTS_REQUEST,
  };
};

export const getContactsSuccess = (payload) => {
  return {
    type: GET_CONTACTS_SUCCESS,
    payload: payload,
  };
};

export const getContactsFailure = (payload) => {
  return {
    type: GET_CONTACTS_FAILURE,
    payload: payload,
  };
};

export const clearContacts = () => {
  return {
    type: CLEAR_CONTACTS,
  };
};
