import { React, useState, useEffect } from "react";
import "./RenderBlogCard.css";
import { Modal, Spinner } from "react-bootstrap";
import SbdButton from "../../../../../components/button/button/SbdButton.jsx";
import LikeButton from "../../../../../components/button/likeButton/LikeButton.jsx";
import { Row, Col } from "react-bootstrap";
import CardContainer from "../../../../../components/container/card-container/CardContainer.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  getPosts,
  getPost,
} from "../../../../../utils/redux/actions/postsAction.js";
import { postLike } from "../../../../../utils/redux/actions/likeAction.js";
import moment from "moment";
import {
  clearPost,
  clearPosts,
} from "../../../../../utils/redux/types/postsTypes";
import News from "../../../../../assets/images/news.jpg";
import parse from "html-react-parser";

const RenderBlogCard = () => {
  // *************ALL POSTS****************
  const [posts, setPosts] = useState([]);

  const dispatch = useDispatch();
  const postsRedux = useSelector((state) => state?.postsState);
  const loading = postsRedux?.loading;
  const error = postsRedux?.error?.state;

  // ******************ONLY ONE POST*****************

  const post = useSelector((state) => state?.postsState?.post);

  const [showBlog, setShowBlog] = useState(false);

  function getPostById(id) {
    dispatch(getPost(id));
    setShowBlog(!showBlog);
  }

  // ****************LIKE BUTTON POST*****************

  function getLike(id) {
    dispatch(postLike(id));
  }

  function handleOnHide() {
    setShowBlog(!showBlog);
    dispatch(clearPost());
  }

  useEffect(() => {
    setPosts(postsRedux?.posts || []);
  }, [postsRedux]);

  useEffect(() => {
    dispatch(getPosts());
    return () => {
      dispatch(clearPosts());
    };
  }, [dispatch]);

  return (
    <>
      <Row className=" justify-content-lg-around justify-content-sm-center px-1 mx-2">
        {loading && !showBlog ? (
          <Row className="justify-content-center">
            <Spinner animation="border" role="status" variant="light" />
          </Row>
        ) : error ? (
          <Row className="justify-content-center bg-light text-danger error-message text-center">
            OOpss... There was a problem, please try again later.
          </Row>
        ) : (
          posts.map((item, index) => {
            return (
              <Col
                md={10}
                lg={6}
                xxl={4}
                className="p-4 my-2 d-flex justify-content-center "
                key={`blog-card-${index}`}
              >
                <CardContainer
                  id={`${item?._id}`}
                  minWidth={300}
                  minHeight={500}
                  maxHeight={1000}
                  maxWidth={400}
                >
                  <div className="d-flex flex-column container-content-blog-card align-items-center justify-content-between h-100">
                    <Row className="align-items-center justify-content-center flex-wrap mb-3">
                      <img
                        className="card-avatar w-auto"
                        src={
                          item?.postPhoto && item?.postPhoto !== "postPhoto"
                            ? item?.postPhoto
                            : News
                        }
                        alt="imagine-post blog"
                        width="100%"
                        height="100%"
                      />
                    </Row>
                    <Row className="justify-content-center align-items-center mh-100">
                      <Col xs={12}>
                        <p className="text-center title-card-blog">
                          {item?.postTitle}
                        </p>
                        <p className="text-center subtitle-card-blog">
                          {item?.postSubTitle}
                        </p>
                      </Col>
                    </Row>
                    <SbdButton
                      id={`1button-blog${item?._id}`}
                      variant="primary"
                      title="Read more"
                      handleClick={() => getPostById(item._id)}
                    />
                  </div>
                </CardContainer>
              </Col>
            );
          })
        )}
        <Modal
          key={`blog-card-modal`}
          show={showBlog}
          onHide={handleOnHide}
          size="xl"
        >
          <Modal.Header className="flex-column">
            <Row className="menu-close-button w-100 text-end">
              <i
                className="bi bi-x-lg mx-0 px-0"
                onClick={() => setShowBlog(!showBlog)}
              ></i>
            </Row>
            <Row>
              <Modal.Title className="w-100">
                <p className="text-center w-100 title-post-blog">
                  {post?.postTitle}
                </p>
                <p className="text-center w-100 subtitle-post-blog">
                  {post?.postSubTitle}
                </p>
              </Modal.Title>
            </Row>
          </Modal.Header>
          <Modal.Body>
            {loading ? (
              <Row className="justify-content-center">
                <Spinner animation="grow" role="status" size="lg" />
              </Row>
            ) : error ? (
              <Row className="justify-content-center text-danger text-center error-message">
                OOpss... There was a problem, please try again later.
              </Row>
            ) : (
              <Row
                className="justify-content-center align-items-center"
                key={`modal-member-${posts?._id}`}
              >
                <Row>
                  <p>{moment(post?.updatedAt).format("ll")}</p>
                </Row>
                <Row className="my-2">
                  <img
                    className="card-avatar"
                    height={150}
                    src={post?.postPhoto ? post?.postPhoto : News}
                    alt="img-blog"
                  />
                </Row>
                <Row className="my-3 justify-content-center">
                  <div className="text-center w-75 description-card-blog">
                    {parse(post?.postDescription || "")}
                  </div>
                </Row>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Row className="flex-row align-items-center justify-content-between w-100 mx-0">
              <Col xs={7} className="my-2 p-0">
                <Row className="flex-row mx-2">
                  <LikeButton
                    width={120}
                    key={`like-button-${post._id}`}
                    handlerClick={() => getLike(post?._id)}
                  />
                  <div className="d-flex modal-post-data align-items-center m-0">
                    {post?.likes}
                  </div>
                </Row>
              </Col>
              <Col xs={5} className="m-0 p-0 ">
                {showBlog ? (
                  <p
                    className="read-more-toggle p-4 text-center m-0"
                    onClick={handleOnHide}
                  >
                    Read less
                  </p>
                ) : (
                  <p
                    className="read-more-toggle p-3 text-center"
                    onClick={() => setShowBlog(!showBlog)}
                  >
                    Read more
                  </p>
                )}
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </Row>
    </>
  );
};

export default RenderBlogCard;
