import React, { useState, useEffect } from "react";
import "./RenderMemberCard.css";
import { Col, Row, Spinner } from "react-bootstrap";
import CardContainer from "../../../../../components/container/card-container/CardContainer.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getMembers } from "../../../../../utils/redux/actions/membersAction";
import { clearMembers } from "../../../../../utils/redux/types/membersTypes";
import dummyAvatar from "../../../../../assets/images/dummyAvatar.jpg";

const RenderMemberCard = () => {
  const [members, setMembers] = useState([]);

  const dispatch = useDispatch();
  const membersRedux = useSelector((state) => state?.membersState);
  const loading = membersRedux?.loading;
  const error = membersRedux?.error?.status;

  useEffect(() => {
    setMembers(membersRedux?.members || []);
  }, [membersRedux]);

  useEffect(() => {
    dispatch(getMembers());
    return () => dispatch(clearMembers());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Row className="justify-content-center">
          <Spinner animation="border" role="status" variant="light" />
        </Row>
      ) : error ? (
        <Row className="justify-content-center bg-light text-danger error-message text-center mx-0">
          OOpss... There was a problem, please try again later.
        </Row>
      ) : (
        members.map((item, index) => {
          return (
            <Col
              key={`memeber-card-${index}`}
              xxl={4}
              lg={6}
              sm={10}
              className="mx-0 my-4 p-2"
            >
              <CardContainer
                id={`${item?._id}`}
                minWidth={250}
                minHeight={300}
                maxWidth={600}
                maxHeight={600}
                borderRadius={60}
                padding={10}
              >
                <Row className="m-0 p-0 h-100">
                  <Row className="justify-content-around m-0 p-0">
                    <Col className="continer-image m-0 p-1 container-member-profile d-flex justify-content-center">
                      <img
                        className="border m-auto rounded-circle member-profile-avatar"
                        src={
                          item?.avatar && item?.avatar !== "avatar"
                            ? item?.avatar
                            : dummyAvatar
                        }
                        alt={item?.firstName}
                      />
                    </Col>
                    <Col
                      xs={12}
                      className="my-3  d-flex  align-items-center flex-wrap "
                    >
                      <p className="m-0 w-100 p-0 member-name text-center">
                        {item?.firstName} {item?.lastName}
                      </p>
                    </Col>
                    <div className="gradient-line"></div>
                  </Row>
                  <Row className="justify-content-around m-0 p-0">
                    <Row>
                      <p className="m-0 mt-3 px-3 member-details w-100 d-flex align-items-center justify-content-center">
                        {item?.position}
                      </p>
                      <a
                        href={`mailto:${item?.email}`}
                        className="d-flex align-item-center justify-content-center member-email my-3"
                      >
                        <i className="bi bi-envelope mx-2 my-auto " />
                        <p className="text-center member-email-text my-auto">
                          Send email.
                        </p>
                      </a>
                    </Row>
                    <Row className="flex-row justify-content-center my-2">
                      <a
                        href={`${item?.github}`}
                        target="_blanck"
                        aria-label="github"
                        className="member-link d-flex align-items-center justify-content-center"
                      >
                        <i className="bi bi-github m-0 p-0" />
                      </a>
                      <a
                        href={`${item?.linkedIn}`}
                        target="_blanck"
                        aria-label="linkedIn"
                        className="member-link d-flex align-items-center justify-content-center "
                      >
                        <i className="bi bi-linkedin m-0 p-0" />
                      </a>
                    </Row>
                  </Row>
                </Row>
              </CardContainer>
            </Col>
          );
        })
      )}
    </>
  );
};

export default RenderMemberCard;
