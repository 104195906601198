import React from "react";
import "./MemberSection.css";
import { Container, Row } from "react-bootstrap";
import RenderMemberCard from "./renderMemberCard/RenderMemberCard.jsx";

const MemberSection = () => {
  return (
    <Container fluid className="p-0 m-0">
      <Row
        style={{ backgroundColor: "#371b51", borderRadius: "20px" }}
        className="mx-0 my-5 py-5 px-0"
      >
        <Row
          className="w-100 justify-content-center my-3 mx-0"
          id="header-section-members"
        >
          <h2 className="titlu-section-members text-center mb-5">
            Smart Box Digital Specialists
          </h2>
          <p className="description-section-member px-3 text-center">
            We know the importance of delivering the best customer experience
            and we enable your organization to exceed customer expectations.
          </p>
        </Row>
        <Row className="w-100 mx-0 my-5 px-2 py-5 justify-content-sm-center">
          <RenderMemberCard />
        </Row>
      </Row>
    </Container>
  );
};

export default MemberSection;
