export const itemIcons = [
  {
    iconClass: "bi bi-facebook",
    src: "https://www.facebook.com/smartboxdigital",
    aria: "facebook",
  },
  {
    iconClass: "bi bi-linkedin",
    src: "https://www.linkedin.com/company/smart-box-digital/",
    aria: "linkedin",
  },
  {
    iconClass: "bi bi-instagram",
    src: "https://www.instagram.com/smartboxdigital/",
    aria: "instagram",
  },
];
