// types
export const POST_FORMULAR_REQUEST = "POST_FORMULAR_REQUEST";
export const POST_FORMULAR_SUCCESS = "POST_FORMULAR_SUCCESS";
export const POST_FORMULAR_FAILURE = "POST_FORMULAR_FAILURE";
export const CLEAR_FORMULAR = "CLEAR_FORMULAR";
export const CLEAR_ERROR = "CLEAR_ERROR";

// actions
export const postFormularRequest = () => {
  return {
    type: POST_FORMULAR_REQUEST,
  };
};

export const postFormularSuccess = (response) => {
  return {
    type: POST_FORMULAR_SUCCESS,
    payload: response,
  };
};

export const postFormularFailure = (error) => {
  return {
    type: POST_FORMULAR_FAILURE,
    payload: error,
  };
};

export const clearFormular = () => {
  return {
    type: CLEAR_FORMULAR,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};
