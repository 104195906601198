import { request } from "../../api/config/axios-config";
import {
  getPostsRequest,
  getPostsSuccess,
  getPostsFailure,
  getPostRequest,
  getPostSuccess,
  getPostFailure,
} from "../types/postsTypes";

export const getPosts = () => {
  return (dispatch) => {
    dispatch(getPostsRequest());
    request
      .get("get-posts/all")
      .then((res) => {
        const data = res?.data;
        dispatch(getPostsSuccess(data));
      })
      .catch((err) => {
        const data = err?.data;
        dispatch(getPostsFailure(data));
      });
  };
};

export const getPost = (id) => {
  return (dispatch) => {
    dispatch(getPostRequest());
    request
      .get(`get-posts/${id}`)
      .then((res) => {
        const data = res?.data;
        dispatch(getPostSuccess(data));
      })
      .catch((err) => {
        const data = err?.data;
        dispatch(getPostFailure(data));
      });
  };
};
