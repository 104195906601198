import React, { useState, useEffect } from "react";
import "./TopButton.css";

const TopButton = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {showTopBtn ? (
        <div className="top-to-btm icon-style m-0 p-0 ">
          <div
            className=" d-flex align-items-center w-100 h-100 px-1 mx-2"
            onClick={goToTop}
          >
            Up
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default TopButton;
