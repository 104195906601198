import {
  POST_LIKE_REQUEST,
  POST_LIKE_SUCCESS,
  POST_LIKE_FAILURE,
} from "../types/likeTypes.js";

let initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
  },
};

const likeReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_LIKE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_LIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          status: false,
          message: action?.payload?.message,
        },
      };
    case POST_LIKE_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          message: action?.payload?.message,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default likeReducer;
