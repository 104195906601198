import React from "react";
import "./HomeBlogSection.css";
import { Container, Row } from "react-bootstrap";
import RenderBlogCard from "./renderBlogCard/RenderBlogCard.jsx";

const HomeBlogSection = () => {
  return (
    <Container fluid id="blog-card-section">
      <Row className="w-100 align-items-center  my-5 py-5 px-0 mx-0">
        <Row className="justify-content-center mx-0 px-0">
          <h2 className="title-blog-section text-center">Blog and Posts</h2>
        </Row>
        <Row className="justify-content-center mx-0 p-0 my-5">
          <p className="text-center description-blog-section w-100">
            SmartBoxDigital is the next gen of creating new
            technologies/innovations involves exploiting a space in the
            environment through entrepreneurial or new-business development
            activities.
          </p>
        </Row>
        <RenderBlogCard />
      </Row>
    </Container>
  );
};

export default HomeBlogSection;
