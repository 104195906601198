import Database from "../../assets/ilustration/database-ilustration.svg";
import Graphic from "../../assets/ilustration/graphic-design.svg";
import Marketing from "../../assets/ilustration/marketing.svg";
import Consultancy from "../../assets/ilustration/consultancy.svg";
import Web from "../../assets/ilustration/web-develop.svg";

export const itemsHomeWhatWeDo = [
  {
    icon: `${Graphic}`,
    title: "User Experience Design",
    description:
      "Every time a solution is started from scratch we always make sure we envision and design the prototype before coding.",
    arange: "",
  },
  {
    icon: `${Web}`,
    title: "Software Development",
    description:
      "We are building solutions for various industries by using the best engineering practices, cutting edge technologies and the latest tools.",
    arange: "justify-content-end",
  },
  {
    icon: `${Marketing}`,
    title: "DevOps process",
    description:
      "Our main focus is to never compromise the quality and create the best environment for our customers in order to continuously deliver the best solutions and allow constant feedback.",
    arange: "",
  },
  {
    icon: `${Database}`,
    title: "Agile testing",
    description:
      "We consider testing is at least as important as coding. We make sure we follow the Agile testing quadrants and assure solutions for all phases.",
    arange: "justify-content-end",
  },
  {
    icon: `${Consultancy}`,
    title: "Consultancy services",
    description:
      "From identifying the suitable technologies to integrating with the best already built solutions, our focus is to help our customers to reduce costs, improve quality and come up with the best long-term scalable products.",
    arange: "",
  },
];
