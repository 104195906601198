import React from "react";
import "./About.css";
import AboutHeroSection from "./aboutComponents/aboutHeroSection/AboutHeroSection.jsx";
import ConceptSection from "./aboutComponents/conceptSection/ConceptSection.jsx";
import MemberSection from "./aboutComponents/memberSection/MemberSection.jsx";
import { Container, Row, Col } from "react-bootstrap";

const About = () => {
  return (
    <Container fluid>
      <Row className="flex-column align-items-center m-0">
        <Col md={8} xs={12} className="p-0 m-0">
          <AboutHeroSection />
          <ConceptSection />
          <MemberSection />
        </Col>
      </Row>
    </Container>
  );
};

export default About;
