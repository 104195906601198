import React from "react";
import "./RenderCardWhatWeDo.css";
import { Col, Row } from "react-bootstrap";
import { itemsHomeWhatWeDo } from "../../../../../utils/data/itemsHomeWhatWeDo.js";
import CardContainer from "../../../../../components/container/card-container/CardContainer.jsx";

const RenderCardWhatWeDo = () => {
  return (
    <>
      {itemsHomeWhatWeDo.map((item, index) => {
        return (
          <Row
            key={`what-we-do-item-${index}`}
            className="align-items-center mb-3 px-0 py-3 "
          >
            <Col className={`d-flex m-4 p-0  ${item.arange}`}>
              <CardContainer
                id={`${index}`}
                minWidth={100}
                minHeight={300}
                maxWidth={900}
                maxHeight={800}
                className="d-flex align-items-center"
              >
                <div className="d-flex w-100 h-100 justify-content-between flex-wrap flex-md-nowrap p-relative align-items-center m-0 p-0">
                  <img
                    id="card-icon"
                    className="col-lg-5 col-md-6 col-sm-12 p-3"
                    src={item.icon}
                    width={400}
                    height={300}
                    alt="icon"
                  />
                  <div
                    className="col-lg-7 col-md-6 col-sm-12 mr-5 p-3"
                    id="card-description"
                  >
                    <h3 className=" w-100 mx-auto">{item.title}</h3>
                    <div className="gradient-line"></div>
                    <p className="w-100 mr-5 pr-5 mt-4 mx-auto">
                      {item.description}
                    </p>
                  </div>
                </div>
              </CardContainer>
            </Col>
          </Row>
        );
      })}
    </>
  );
};
export default RenderCardWhatWeDo;
