import React from "react";
import "./CareerWhyRemoteSection.css";
import { Container, Row } from "react-bootstrap";
import RenderRemoteCard from "./renderRemoteCard/RenderRemoteCard.jsx";

const CareerWhyRemoteSection = () => {
  return (
    <Container
      fluid
      style={{ backgroundColor: "#371b51", borderRadius: "20px" }}
      className="m-0 px-3 py-0"
    >
      <Row className="m-0 mb-3 p-0  ">
        <p className=" w-100 mt-5 title-why text-center">
          Why <span className="title-section-remote">remote</span>?
        </p>
      </Row>
      <Row className="m-0 p-0 w-100 justify-content-center py-5">
        <RenderRemoteCard />
      </Row>
    </Container>
  );
};

export default CareerWhyRemoteSection;
