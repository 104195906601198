import {
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAILURE,
  CLEAR_CONTACTS,
} from "../types/contactsTypes.js";

const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
  },
  contacts: {},
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          status: false,
          message: action?.payload?.message,
        },
        contacts: action?.payload?.contacts,
      };
    case GET_CONTACTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          message: action?.payload?.message,
        },
      };
    case CLEAR_CONTACTS:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default contactsReducer;
