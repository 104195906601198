import astronaut from "../../assets/ilustration/astronaut-ilustration.svg";
import consultancy from "../../assets/ilustration/consultancy.svg";
import database from "../../assets/ilustration/database-ilustration.svg";
import graphic from "../../assets/ilustration/graphic-design.svg";
import marketing from "../../assets/ilustration/marketing.svg";
import web from "../../assets/ilustration/web-develop.svg";

export const itemRemoteCard = [
  {
    title: "A larger candidate pool means more qualified candidates",
    image: `${astronaut}`,
    content: `Remote staffing is great because you can get qualified individuals from all over the
        world who may not have been available for an in-person position at your company.
        On top of that, more candidates are looking to work remotely, so many are already
        searching for these types of jobs.`,
    path: "/",
  },
  {
    title: "Reduce employee absences",
    image: `${web}`,
    content: `A huge benefit of remote staffing is that there are fewer employees absent. If
        someone is feeling sick, they’ll often stay home. But, if they’re a remote worker who
        feels sick, chances are, they’ll still be checking in or working depending on how sick
        they are. It allows for more work to get done while also giving employees flexibility.`,
    path: "/",
  },
  {
    title: "Save on costly office space",
    image: `${consultancy}`,
    content: `The cost of real estate is at an all-time high, so there’s no better way to save than
        cutting out those expenses. Plus, technology has evolved to a point that makes
        old-school office settings unnecessary for today’s modern and sophisticated workforce.`,
    path: "/",
  },
  {
    title: "Cut down on turnover",
    image: `${database}`,
    content: `A comfortable employee is a happy, motivated, and productive employee. We all
        know that there’s no place like home, so what better way to offer your staff the
        option of working where they choose.`,
    path: "/",
  },
  {
    title: "Decreased costs",
    image: `${graphic}`,
    content: `Since IT administrators can address certain problems off site, it
        eliminates the need to have an in-person visit for addressing every little issue that
        may occur. This reduces downtime and the increased costs of having someone
        drive out to your office. That can add up to substantial savings.`,
    path: "/",
  },
  {
    title: "Recruitment",
    image: `${marketing}`,
    content: `Our uniquely personal approach to the recruitment process ensures that only the
        most competent professionals are considered for employment. After working
        closely with a client to identify their staffing needs and requirements, our recruiters
        quickly conduct a search for the most highly qualified people – and then meet every
        candidate in person to evaluate his or her qualifications and suitability for the
        specific position available.
        An IT Recruitment team is allocated for each opportunity. From there, we validate
        the most suitable candidates from a pool of CV’s we gather. Then, we will present a
        shortlist of profiles for each role for the desired team.`,
    path: "/",
  },
  {
    title: "Customer Involvement in Recruitment",
    image: `${web}`,
    content: `The customer can choose the best CV’s for each role. If the customer wishes to
        see more suitable profiles, we will deliver newer candidates. It ́s expected from the
        client to handle a second phase of interviews (technical).`,
    path: "/",
  },
];
