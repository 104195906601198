import {
  POST_FORMULAR_REQUEST,
  POST_FORMULAR_SUCCESS,
  POST_FORMULAR_FAILURE,
  CLEAR_FORMULAR,
  CLEAR_ERROR,
} from "../types/formularTypes.js";

let initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
  },
};

const formularReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_FORMULAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_FORMULAR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          status: false,
          message: action?.payload?.message,
        },
      };
    case POST_FORMULAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          message: action?.payload?.message,
        },
      };
    case CLEAR_FORMULAR:
      return {
        ...initialState,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export default formularReducer;
