import React from "react";
import "./FirstSection.css";
import Sbd from "../../../assets/logo//SBDLogo.png";
import renderIcons from "./renderIcons.js";
import { itemIcons } from "./itemIcons.js";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";

const FirstSection = () => {
  return (
    <Col className="first-section ">
      <Row
        className={`row justify-content-center align-items-center ${
          window.innerWidth < 770 ? "my-5" : "my-2"
        }`}
      >
        <LinkContainer to="/">
          <img className="SBDLogo w-100 h-auto" src={Sbd} alt="sbd-logo"></img>
        </LinkContainer>
        <p className="my-5 text-center">
          We'll take care of your digital problems, so you can have the time to
          run your company properly.
        </p>
        <div className="socialmediaicons mx-0 px-0 w-100 h-100 my-4 d-flex flex-row ">
          {renderIcons(itemIcons)}
        </div>
      </Row>
    </Col>
  );
};

export function TagTypesExample() {
  return (
    <>
      <Button href="#">Link</Button> <Button type="submit">Button</Button>{" "}
    </>
  );
}

export default FirstSection;
