import {
  GET_POSTS_REQUEST,
  GET_POSTS_SUCCESS,
  GET_POSTS_FAILURE,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
  GET_POST_FAILURE,
  CLEAR_POSTS,
  CLEAR_POST,
} from "../types/postsTypes.js";

// ***********FOR ALL POSTS****************

const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
  },
  posts: [],
  post: {},
};
const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POSTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          state: false,
          message: action?.payload?.message,
        },
        posts: action?.payload?.posts,
      };
    case GET_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          state: true,
          message: action?.payload?.message,
        },
      };
    case GET_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          state: false,
          message: action?.payload?.message,
        },
        post: action?.payload?.post,
      };
    case GET_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          state: true,
          message: action?.payload?.message,
        },
      };
    case CLEAR_POSTS:
      return {
        ...initialState,
      };
    case CLEAR_POST:
      return {
        ...state,
        error: { ...initialState.error },
        post: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export default postsReducer;
