import React from "react";
import PropTypes from "prop-types";
import "./PurpleContainer.css";

const PurpleContainer = (props) => {
  const { id, maxHeight } = props;

  return (
    <div
      key={id}
      className="bg p-0"
      style={{
        maxHeight: `${maxHeight}px`,
      }}
    >
      {props.children}
    </div>
  );
};

PurpleContainer.defaultProps = {
  id: "",
  image: "",
  title: "",
  description: "",
  button: "",
  input: "",
  maxHeight: "",
};

PurpleContainer.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.string,
  input: PropTypes.string,
  maxHeight: PropTypes.string,
};

export default PurpleContainer;
